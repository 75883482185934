import React from 'react';
import logo from '../assets/images/logo.svg';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, NavLink, Switch } from 'react-router-dom';
import '../App.css';
function Header() {
    return (
        <div className="App">
            <div class="container">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="container-fluid">
                        <Link to="/" class="navbar-brand" href="index.html"><img src={logo} alt='logo'/></Link>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item item-space">
                                    <Link to="/" class="nav-link navigation" href="#">Home</Link>
                                </li>
                                <li class="nav-item dropdown item-space">
                                    <a class="nav-link navigation dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services<i class="fa-solid fa-caret-down"></i>
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/web-development" class="dropdown-item services" href="#">Web Design & Development<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">Transform your online presence and drive growth with our professional web design and development services.</label>
                                                    </li>
                                                </div>
                                                <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/Ecommerce" class="dropdown-item services" href="#">E-Commerce<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">Elevate your online business with our customized ecommerce solutions. Contact us today to discuss your project requirements and embark on a journey to digital success.</label>
                                                    </li>
                                                </div>
                                                <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/mobile-development" class="dropdown-item services" href="#">Mobile Application Development<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">Unlock the potential of mobile technology and elevate your business with our professional mobile app development services.</label>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container">
                                            <div class="row mt-3">
                                                <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/digital-marketing" class="dropdown-item services" href="#">Digital Marketing<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">Transform your online presence and drive business growth with our comprehensive digital marketing solutions.</label>
                                                    </li>
                                                </div>
                                                <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/Quality" class="dropdown-item services" href="#">Quality Engineering<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">Partner with us to elevate the quality of your software or product. Contact us today to discuss your project requirements and embark on a journey to superior quality</label>
                                                    </li>
                                                </div>
                                                {/* <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/Support" class="dropdown-item services" href="#">Maintenance & Support<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">supports and maintain for your business requirements.</label>
                                                    </li>
                                                </div> */}

                                                <div class="col-lg-4 col-md-8 col-sm-12">
                                                    <li>
                                                        <Link to="/staffing" class="dropdown-item services" href="#">Staffing Solutions<i class="fa-solid fa-arrow-right-long"></i></Link>
                                                        <label class="item-description">We are dedicated to helping businesses like yours thrive by connecting you with top-tier talent that drives success.</label>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </ul>
                                </li>
                                <li class="nav-item item-space">
                                    <Link to="/Products" class="nav-link navigation product-link" href="#">Our Products</Link>
                                </li>
                                <li class="nav-item item-space">
                                    <a href="http://blogs.zastainfotek.com" target="_blank" class="nav-link navigation">Blog</a>
                                </li>
                                <li class="nav-item item-space">
                                    <Link to="/about-us" class="nav-link navigation" href="#">About</Link>
                                </li>
                                <li class="nav-item item-space">
                                    <Link to="/contact-us" class="nav-link navigation" href="#">Contact</Link>
                                </li>

                            </ul>
                            <form class="d-flex">
                                {/* <Link to="/Contact" button class="demo-btn">Talk to Us</Link> */}
                                <Link to="/contact-us"><button class="talk-main mt-4" data-aos="fade-up" data-aos-duration="1500">Talk to us</button></Link>

                            </form>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Header;
