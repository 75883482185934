import React from "react";
import './Quality.css';
import { Link } from 'react-router-dom';
import qualitymain from '../../assets/images/main-quality.svg';
import t1 from '../../assets/images/testing-1.svg';
import t2 from '../../assets/images/testing-2.svg';
import t3 from '../../assets/images/testing-3.svg';
import t4 from '../../assets/images/testing-4.svg';
import t5 from '../../assets/images/testing-5.svg';
import t6 from '../../assets/images/testing-6.svg';
import t7 from '../../assets/images/testing-7.svg';
import custom from '../../assets/images/custom.svg';
import t8 from '../../assets/images/testing-8.svg';
import t9 from '../../assets/images/testing-9.svg';
import t10 from '../../assets/images/testing-10.svg';
import t11 from '../../assets/images/testing-11.svg';
import t12 from '../../assets/images/testing-12.svg';
import quality from '../../assets/images/quality-img.svg';
const Quality = () =>{

 
  return (
    <div className="App">
      <div class="container-fluid navy-blue">
        <div class="container">
          <div class="row pt-5">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="web-design mt-5" data-aos="fade-right" data-aos-duration="1000">Quality Engineering</div>
              <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">Partner with us to elevate the quality of your software or product. Contact us today to discuss your project requirements and embark on a journey to superior quality with Us.</div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-5">
                    <Link to="/contact-us"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Talk to Us</button></Link>
                  </div>
                </div>
                {/* <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-5">
                    <a href="#"><button class="learn-more-button" data-aos="fade-up" data-aos-duration="1500">Learn More</button></a>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div  data-aos="fade-down" data-aos-duration="1500">
              <img src={qualitymain} class="w-100 mt-5 e-commerce-main" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
  <div class="started pt-5">What We do?</div>
      <div class="start-description pt-3">Elevate Your Quality Standards</div>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="heading mt-3">Quality Engineering</div>
            <div class="sub-heading mt-4">Your trusted partner for quality engineering solutions. We specialize in delivering comprehensive quality engineering services that ensure the highest standards of software and product quality throughout the development lifecycle. With our expertise in quality assurance and testing methodologies, we help businesses achieve superior quality, reliability, and customer satisfaction.
            </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <img src={quality} class="center w-100 mt-5"></img>
        </div>
    </div>
  </div>
      <div class="container">
        <div class="row">
          <div class="started pt-5">Test Strategy and Planning</div>
          <div class="start-description pt-3">We develop robust test strategies and plans tailored to your specific project requirements. Our experienced quality engineers work closely with your team to define testing objectives, identify risks, prioritize testing efforts, and establish a roadmap for comprehensive testing coverage.
          </div>
        </div>
        <div class="row mt-5">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="decoration">
          <div class="mobile-box">
          <div class="feature-box" data-aos="zoom-in-down" data-aos-duration="1000">
          <img src={t1} class="mt-1 testing-img"></img>
            <div class="feature-heading mt-2">Test Strategy and Planning</div>
            <div class="feature-description pt-2">We develop robust test strategies and plans tailored to your specific project requirements. Our experienced quality engineers work closely with your team to define testing objectives, identify risks, prioritize testing efforts, and establish a roadmap for comprehensive testing coverage.</div>
        
          </div>
</div>
         </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
         <a href="#" class="decoration">
         <div class="mobile-box">
         <div class="feature-box" data-aos="zoom-in-down" data-aos-duration="1000">
         <img src={t2} class="mt-1 testing-img"></img>
           <div class="feature-heading mt-2">Test Automation</div>
           <div class="feature-description pt-2">We harness the power of test automation to streamline and accelerate your testing processes. Our automation experts utilize industry-leading tools and frameworks to design and implement robust automated test suites, allowing for efficient execution, faster time to market, and improved test coverage.</div>
           
         </div>
</div>
        </a>
       </div>
       <div class="col-lg-3 col-md-6 col-sm-12">
         <a href="#" class="decoration">
         <div class="mobile-box">
         <div class="feature-box responsive-box" data-aos="zoom-in-down" data-aos-duration="1000">
         <img src={t3} class="mt-1 testing-img"></img>
          <div class="feature-heading mt-2">Functional Testing</div>
           <div class="feature-description pt-2">We perform rigorous functional testing to ensure that your software or product meets the intended requirements and functions as expected. Our dedicated team of quality engineers conducts comprehensive functional tests, including unit testing, integration testing, system testing, and user acceptance testing. </div>
          
         </div>
</div>
        </a>
       </div>
       <div class="col-lg-3 col-md-6 col-sm-12">
         <a href="#" class="decoration">
         <div class="mobile-box">
         <div class="feature-box responsive-box" data-aos="zoom-in-down" data-aos-duration="1000">
         <img src={t4} class="mt-1 testing-img"></img> 
           <div class="feature-heading mt-2">Performance Testing</div>
           <div class="feature-description pt-2">We assess the performance, scalability, and responsiveness of your software or product under various load conditions. Our performance testing experts simulate real-world scenarios, conduct stress tests, and analyze system behavior to identify bottlenecks, optimize performance, and ensure a smooth user experience.</div>
           
         </div>
</div>
        </a>
       </div>
      </div>
      <div class="row mt-3 pb-3">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="decoration">
          <div class="mobile-box">
          <div class="feature-box" data-aos="zoom-in-up" data-aos-duration="2000">
          <img src={t7} class="mt-1 testing-img"></img> 
            <div class="feature-heading mt-2">Security Testing</div>
            <div class="feature-description pt-2">We prioritize the security of your software or product. Our security testing specialists conduct thorough assessments, vulnerability scans, penetration testing, and code reviews to identify potential security risks and vulnerabilities. We help you strengthen your defenses and safeguard your sensitive data.</div>
           
          </div>
</div>
         </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
         <a href="#" class="decoration">
         <div class="mobile-box">
         <div class="feature-box margin" data-aos="zoom-in-up" data-aos-duration="2000">
         <img src={t6} class="mt-1 testing-img"></img> 
           <div class="feature-heading mt-2">Usability Testing</div>
           <div class="feature-description pt-2">We evaluate the user-friendliness and overall user experience of your software or product. Our usability testing experts conduct user research, perform usability tests, and gather valuable insights to optimize the design, navigation, and functionality, ensuring a seamless and intuitive user experience..</div>
          
         </div>
</div>
        </a>
       </div>
       <div class="col-lg-3 col-md-6 col-sm-12">
         <a href="#" class="decoration">
         <div class="mobile-box">
         <div class="feature-box margin responsive-box" data-aos="zoom-in-up" data-aos-duration="2000">
         <img src={custom} class="mt-1 testing-img"></img> 
          <div class="feature-heading mt-2">Mobile App Testing</div>
           <div class="feature-description pt-2">We specialize in testing mobile applications across various platforms and devices. Our mobile app testing services cover functional testing, usability testing, compatibility testing, performance testing, and security testing, ensuring that your app delivers a flawless experience to your users. </div>
           
         </div>
</div>
        </a>
       </div>
       {/* <div class="col-lg-3 col-md-6 col-sm-12">
         <a href="#" class="decoration">
         <div class="feature-box margin responsive-box" data-aos="zoom-in-up" data-aos-duration="2000">
           <div class="feature-heading">Regression Testing</div>
           <div class="feature-description pt-4">Evaluates the functionality of new code and that new coding doesn't interrupt existing coding. Assures there are no defects or bugs after updates.</div>
           <img src={t8} class="mt-3 testing-img"></img> 
         </div>
        </a>
       </div> */}
      </div>

      </div>

    </div>
  );
}

export default Quality;
