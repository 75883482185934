import React, { useEffect, useState } from "react";
import send from "../assets/images/send.svg";
import logo from "../assets/images/logo.svg";
import facebook from "../assets/images/facebook-f.svg";
import linkedin from "../assets/images/linkedin-f.svg";
import insta from "../assets/images/insta.svg";
import imgmodal from "../assets/images/imgmodal.png";
import twitter from "../assets/images/twitter-f.svg";
import { db } from "../firebase";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  Switch,
} from "react-router-dom";
import "../App.css";

// Main component
function Footer() {
  const[test,Settest]=useState(false)
  console.log('testing test',test)
  const [email, setEmail] = useState("");

useEffect(()=>{
  if(email.length===0){
    Settest(false)
  }
})

  const handleEmailChange = (e) => {
    setEmail(e.target.value);

    Settest(true)
  };

  const handleSaveEmail = async () => {
    if (email.trim() !== "") {
      await db.collection("newsletter").add({
        email: email,
      });
    setEmail('')
    }
  };
  return (
    <div class="container-fluid black pb-4">
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="modal-box">
                <img src={imgmodal} class="w-100" />
              </div>
              <div class="thanks mt-4">Thank you for contacting us!</div>
              <div class="modal-txt mt-4">
                Your submission has been received,
                <br />
                We will be in touch and our team will reach you out soon
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="submit-button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Back to Site
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-12 col-sm-12">
            <div class="newsletter-heading pt-5">
              Subscribe to our Newsletter
            </div>
            <div class="footer-links pt-2">
              To get all the latest Updates about Zasta Infotek
            </div>
            <form onClick={handleSaveEmail}>
              <div class="d-flex box-position">
                <input
                  type="text"
                  placeholder="Your Work email address*"
                  class="e-mail-box mt-3"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              {test?(  <img
                  src={send}
                  class="send-img"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  alt="send-img"
                />):(  <img
                  src={send}
                  class="send-img"
                
                  alt="send-img"
                />)}
              </div>
            </form>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="focus pt-5 center">Company</div>
            <div class="pt-3">
              <Link to="/about-us" class="footer-links center">
                About us
              </Link>
            </div>
            <div class="pt-3">
              <a
                href="https://blogs.zastainfotek.com"
                target="_blank"
                class="footer-links center"
              >
                Blogs
              </a>
            </div>
            <div class="pt-3">
              <a href="/contact-us" class="footer-links center">
                Careers
              </a>
            </div>
            <div class="pt-3">
              <Link to="/contact-us" class="footer-links center">
                Contact
              </Link>
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="focus pt-5 center">Legal</div>
            <div class="pt-3">
              <Link to="/Terms" target="_blank" class="footer-links center">
                Terms of Service
              </Link>
            </div>
            <div class="pt-3">
              <Link to="/Privacy" target="_blank" class="footer-links center">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
        <hr class="footer-line mt-3" />
        <div class="row mt-2">
          <div class="col-lg-2 col-md-12 col-sm-12">
            <Link to="/" class="navbar-brand" href="index.html">
              <img src={logo} class="logo-footer center mt-4" alt="logo" />
            </Link>
          </div>
          <div class="col-lg-7 col-md-12 col-sm-12">
            <div class="copy-txt">
              © All Rights Reserved. 2023. Zasta Infotek Private Limited - CIN :
              U72501TN2019PTC130416
            </div>
          </div>
          <div class="col-lg-3 col-md-12 col-sm-12">
            <div class="footer-flex mt-1">
              <a
                href="https://www.facebook.com/zastainfotek"
                target="_blank"
                class="navbar-brand"
              >
                <img src={facebook} class="img-footer" alt="facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/zastainfotek"
                target="_blank"
                class="navbar-brand"
              >
                <img src={linkedin} class="img-footer" alt="linkedin" />
              </a>
              <a
                href="https://www.instagram.com/zastainfotek/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
                target="_blank"
                class="navbar-brand"
              >
                <img src={insta} class="img-footer" alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
