import React from 'react';
import './App.css';
import Header from './components/Footer';
import Footer from './components/Header';
function Privacy() {
    return (
        <div className="App">
            <Header />
            <div class="container-fluid background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="privacy">PRIVACY POLICY</div>
                            <div class="zasta-privacy pt-3">Zasta Infotek Pvt Ltd is a Technology service provider<br />for any businesses,</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5">
                <div>
                    <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                    <div class="policy-heading mt-3">General information</div>
                    <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                    <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                    <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                    <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                    <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                    <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                    <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                    <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                    <div class="policy-para mt-3">Contact forms other forms of contact</div>
                    <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                    <div class="policy-heading mt-3">Newsletter</div>
                    <div class="policy-para mt-3">The data provided to the Administrator when subscribing to the Newsletter is used to send you messages containing news and information, as well as commercial, business and marketing materials. By subscribing to the Newsletter, you agree to receive marketing and commercial content to the provided e-mail address. Consent is not mandatory, but it is required in order to subscribe to the Newsletter. The legal basis for data processing is your consent to receive the ordered Newsletter and the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). The data will be processed for the duration of the Newsletter’s operation, or until you unsubscribe from the Newsletter. You can unsubscribe from the Newsletter at any time, the appropriate link is always included in each Newsletter message. You can also contact us via e-mail.</div>
                    <div class="policy-heading mt-3">Analysis of the Website usage</div>
                    <div class="policy-para mt-3">The Administrator adjusts and develops the functionalities of the Website, including its structure and content, creates aggregated statistics and cares for the safety and quality of services, in particular:</div>
                    <div class="policy-para mt-3">adjusts the way the Website is displayed,</div>
                    <div class="policy-para">saves data from forms in order to continue the session and facilitate the use of the Website,</div>
                    <div class="policy-para">analyzes the traffic on the Website in order to improve its functioning and protect against abuse.</div>
                    <div class="policy-para">When you use the Website, we can save data such as your IP address, type and version of your device and web browser, your decisions with respect to cookies, and the way in which you use the Website. In most cases, we will not be able to identify you as a user and this data will be anonymous to us. However, if we are able to associate this data with you, which may occur when we are in possession of additional data from another source (e.g. if we save such data when you contact us via the contact form available on the Website), this data may become your personal data for us. The legal basis for processing your personal data is the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). Your personal data will be processed for the period necessary to achieve the purpose mentioned above.</div>
                </div>
                <div>
                    <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                    <div class="policy-heading mt-3">General information</div>
                    <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                    <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                    <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                    <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                    <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                    <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                    <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                    <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                    <div class="policy-para mt-3">Contact forms other forms of contact</div>
                    <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                    <div class="policy-heading mt-3">Newsletter</div>
                    <div class="policy-para mt-3">The data provided to the Administrator when subscribing to the Newsletter is used to send you messages containing news and information, as well as commercial, business and marketing materials. By subscribing to the Newsletter, you agree to receive marketing and commercial content to the provided e-mail address. Consent is not mandatory, but it is required in order to subscribe to the Newsletter. The legal basis for data processing is your consent to receive the ordered Newsletter and the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). The data will be processed for the duration of the Newsletter’s operation, or until you unsubscribe from the Newsletter. You can unsubscribe from the Newsletter at any time, the appropriate link is always included in each Newsletter message. You can also contact us via e-mail.</div>
                    <div class="policy-heading mt-3">Analysis of the Website usage</div>
                    <div class="policy-para mt-3">The Administrator adjusts and develops the functionalities of the Website, including its structure and content, creates aggregated statistics and cares for the safety and quality of services, in particular:</div>
                    <div class="policy-para mt-3">adjusts the way the Website is displayed,</div>
                    <div class="policy-para">saves data from forms in order to continue the session and facilitate the use of the Website,</div>
                    <div class="policy-para">analyzes the traffic on the Website in order to improve its functioning and protect against abuse.</div>
                    <div class="policy-para">When you use the Website, we can save data such as your IP address, type and version of your device and web browser, your decisions with respect to cookies, and the way in which you use the Website. In most cases, we will not be able to identify you as a user and this data will be anonymous to us. However, if we are able to associate this data with you, which may occur when we are in possession of additional data from another source (e.g. if we save such data when you contact us via the contact form available on the Website), this data may become your personal data for us. The legal basis for processing your personal data is the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). Your personal data will be processed for the period necessary to achieve the purpose mentioned above.</div>
                </div>
            </div>
            <div class="container-fluid background">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="privacy">Cookies</div>
                            <div class="zasta-privacy pt-3">Zasta Infotek Pvt Ltd is a Technology service provider<br />for any businesses,</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5">
                <div>
                    <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                    <div class="policy-heading mt-3">General information</div>
                    <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                    <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                    <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                    <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                    <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                    <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                    <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                    <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                    <div class="policy-para mt-3">Contact forms other forms of contact</div>
                    <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                    <div class="policy-heading mt-3">Newsletter</div>
                    <div class="policy-para mt-3">The data provided to the Administrator when subscribing to the Newsletter is used to send you messages containing news and information, as well as commercial, business and marketing materials. By subscribing to the Newsletter, you agree to receive marketing and commercial content to the provided e-mail address. Consent is not mandatory, but it is required in order to subscribe to the Newsletter. The legal basis for data processing is your consent to receive the ordered Newsletter and the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). The data will be processed for the duration of the Newsletter’s operation, or until you unsubscribe from the Newsletter. You can unsubscribe from the Newsletter at any time, the appropriate link is always included in each Newsletter message. You can also contact us via e-mail.</div>
                    <div class="policy-heading mt-3">Analysis of the Website usage</div>
                    <div class="policy-para mt-3">The Administrator adjusts and develops the functionalities of the Website, including its structure and content, creates aggregated statistics and cares for the safety and quality of services, in particular:</div>
                    <div class="policy-para mt-3">adjusts the way the Website is displayed,</div>
                    <div class="policy-para">saves data from forms in order to continue the session and facilitate the use of the Website,</div>
                    <div class="policy-para">analyzes the traffic on the Website in order to improve its functioning and protect against abuse.</div>
                    <div class="policy-para">When you use the Website, we can save data such as your IP address, type and version of your device and web browser, your decisions with respect to cookies, and the way in which you use the Website. In most cases, we will not be able to identify you as a user and this data will be anonymous to us. However, if we are able to associate this data with you, which may occur when we are in possession of additional data from another source (e.g. if we save such data when you contact us via the contact form available on the Website), this data may become your personal data for us. The legal basis for processing your personal data is the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). Your personal data will be processed for the period necessary to achieve the purpose mentioned above.</div>
                </div>
                <div>
                    <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                    <div class="policy-heading mt-3">General information</div>
                    <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                    <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                    <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                    <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                    <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                    <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                    <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                    <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                    <div class="policy-para mt-3">Contact forms other forms of contact</div>
                    <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                    <div class="policy-heading mt-3">Newsletter</div>
                    <div class="policy-para mt-3">The data provided to the Administrator when subscribing to the Newsletter is used to send you messages containing news and information, as well as commercial, business and marketing materials. By subscribing to the Newsletter, you agree to receive marketing and commercial content to the provided e-mail address. Consent is not mandatory, but it is required in order to subscribe to the Newsletter. The legal basis for data processing is your consent to receive the ordered Newsletter and the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). The data will be processed for the duration of the Newsletter’s operation, or until you unsubscribe from the Newsletter. You can unsubscribe from the Newsletter at any time, the appropriate link is always included in each Newsletter message. You can also contact us via e-mail.</div>
                    <div class="policy-heading mt-3">Analysis of the Website usage</div>
                    <div class="policy-para mt-3">The Administrator adjusts and develops the functionalities of the Website, including its structure and content, creates aggregated statistics and cares for the safety and quality of services, in particular:</div>
                    <div class="policy-para mt-3">adjusts the way the Website is displayed,</div>
                    <div class="policy-para">saves data from forms in order to continue the session and facilitate the use of the Website,</div>
                    <div class="policy-para">analyzes the traffic on the Website in order to improve its functioning and protect against abuse.</div>
                    <div class="policy-para">When you use the Website, we can save data such as your IP address, type and version of your device and web browser, your decisions with respect to cookies, and the way in which you use the Website. In most cases, we will not be able to identify you as a user and this data will be anonymous to us. However, if we are able to associate this data with you, which may occur when we are in possession of additional data from another source (e.g. if we save such data when you contact us via the contact form available on the Website), this data may become your personal data for us. The legal basis for processing your personal data is the legitimate interest of the Administrator (Article 6 (1) (f) of the GDPR). Your personal data will be processed for the period necessary to achieve the purpose mentioned above.</div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Privacy;
