import React, { useState, useEffect } from "react";
import arrow from "../../assets/images/arrow.svg";
import imgmodal from "../../assets/images/imgmodal.png";
import location from "../../assets/images/location.svg";
import contactmobile from "../../assets/images/contact-mobile.svg";
import email from "../../assets/images/contact-mail.svg";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_y18yjho",
        "template_lvcz3d7",
        e.target,
        "tPVKUKbHFllq5r78n"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  const [name, setName] = useState("");
  const [mail, setEmail] = useState("");
  const [mobile, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    if(e){ Settest(true);}
    sendEmail(e);
    e.preventDefault();

    db.collection("details").add({
      name: name,
      mail: mail,
      mobile: mobile,
      message: message,
    });
   
    setName("");
    setEmail("");
    setNumber("");
    setMessage("");
  }
  


  const [test, Settest] = useState(false);
  console.log("testing test", test);
  useEffect(() => {
    if (
      mail.length === 0 ||
      name.length === 0 ||
      mobile.length === 0 ||
      message.length == 0
    ) {
      Settest(false);
    }
    if (
        mail.length &&
        name.length &&
        mobile.length &&
        message.length 
      ) {
        Settest(true);
      }
  });
  return (
    <div className="App">
      <div class="container-fluid navy-blue">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 contact-section">
              <div
                class="contact-us"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Reach out to uS
              </div>
              <div
                class="contact-txt mt-2"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Reach Out for Inquiries, Collaborations, and 
                <br />
                Expert Technology Solutions,
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid yellow space-sm">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="modal-box">
                  <img src={imgmodal} class="w-100" alt="imgmodal" />
                </div>
                <div class="thanks mt-4">Thank you for contacting us!</div>
                <div class="modal-txt mt-4">
                  Your submission has been received,
                  <br />
                  We will be in touch and our team will reach you out soon
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="submit-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Back to Site
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <img src={arrow} class="arrow-img pt-5 pb-5" alt="arrow" />
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div
              class="contact-box"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div class="project">Drop us a line</div>
              <form onSubmit={handleSubmit}>
                <div class="req-field mt-4">
                  <span class="important">*</span> Your Name
                </div>
                <input
                  type="text"
                  class="mt-1"
                  value={name}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <div class="row mt-2">
                  <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="req-field mt-4">
                      <span class="important">*</span> Your E-mail
                    </div>
                    <input
                      type="text"
                      class="mt-1"
                      value={mail}
                      name="mail"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="req-field mt-4">
                      <span class="important">*</span> Your Phone Number
                    </div>
                    <input
                      type="text"
                      class="mt-1"
                      value={mobile}
                      name="mobile"
                      onChange={(e) => setNumber(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div class="req-field pt-4">What do you need</div>
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="flex-checkbox mt-3">
                      &nbsp;{" "}
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label=""
                      />
                      <div class="needs mt-1">&nbsp; Web Development</div>
                    </div>
                    <div class="flex-checkbox mt-3">
                      &nbsp;{" "}
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label=""
                      />
                      <div class="needs mt-1">&nbsp; Graphic Design</div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="flex-checkbox mt-3">
                      &nbsp;{" "}
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label=""
                      />
                      <div class="needs mt-1">
                        &nbsp; Mobile App Development
                      </div>
                    </div>
                    <div class="flex-checkbox mt-3">
                      &nbsp;{" "}
                      <input
                        class="form-check-input"
                        type="radio"
                        name="radioNoLabel"
                        id="radioNoLabel1"
                        value=""
                        aria-label=""
                      />
                      <div class="needs mt-1">&nbsp; Others</div>
                    </div>
                  </div>
                </div>
                <div class="req-field pt-4">Message</div>
                <input
                  type="text"
                  placeholder="(230 words)"
                  name="message"
                  class="mt-3"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
                {test ? (
                  <button
                    class="submit-button mt-5"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Submit
                  </button>
                ) : (
                  <button class="submit-button mt-5">Submit</button>
                )}
 
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="container mb-5">
          <div class="we-are mt-5">WE’RE HERE FOR YOU</div>
          <div class="about-txt mt-2">
            Zasta Infotek Pvt Ltd is a Technology service provider for any
            businesses, found during the year 2019 to
            <br />
            provide customized and efficient software .{" "}
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div
                class="about-us-box mt-5"
                data-aos="flip-left"
                data-aos-duration="1000"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <img src={location} class="about-img" alt="locate" />
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="about-us-heading mt-2">Location</div>
                    <div class="about-us-content mt-2">
                      A13-304, SSM Nagar, Alapakkam, New Perugalathur, Chennai –
                      600063.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div
                class="about-us-box mt-5"
                data-aos="flip-right"
                data-aos-duration="1000"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <img src={contactmobile} class="about-img" alt="mobile" />
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="about-us-heading mt-2">Phone</div>
                    <div class="about-us-content mt-2">+91 8056163769</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div
                class="about-us-box mt-3"
                data-aos="flip-left"
                data-aos-duration="1500"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <img src={email} class="about-img" alt="mail" />
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="about-us-heading mt-2">E-mail</div>
                    <div class="about-us-content mt-2">
                      info@zastainfotek.com{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div
                class="about-us-box mt-3"
                data-aos="flip-right"
                data-aos-duration="1500"
              >
                <div class="row">
                  <div class="col-lg-3 col-md-3 col-sm-3 col-3">
                    <img src={location} class="about-img" alt="loacte" />
                  </div>
                  <div class="col-lg-9 col-md-9 col-sm-9 col-9">
                    <div class="about-us-heading mt-2">
                      Simple and easy form
                    </div>
                    <div class="about-us-content mt-2">Live Chat</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid dark-blue">
        <div class="locate-heading pt-5">LOCATE US</div>
        <div class="locate-txt mt-3">
          Zasta Infotek Pvt Ltd is a Technology service provider
          <br />
          for any businesses
        </div>
        <div class="container">
          <div class="map-box mt-5" data-aos="zoom-in" data-aos-duration="1500">
            <div class="mapwrapper">
              <iframe
                width="100%"
                height="550"
                id="gmap_canvas"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.215863480342!2d80.1172756!3d12.8938367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5258b530000001%3A0x62560a8046a5c78f!2sZasta%20Infotek%20private%20ltd.!5e0!3m2!1sen!2smy!4v1685083303104!5m2!1sen!2smy"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                title="myFrame"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
