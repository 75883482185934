import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import HomeBanner from '../components/Home/HomeBanner';
import VisionStatement from '../components/Home/VisionStatement';
import Footer from '../components/Footer';
import WeDo from '../components/Home/WeDo';
import Clients from '../components/Home/Clients';
import SendQuery from '../components/SendQuery';
import { Technology } from '../components/Home/Technology';
import Chatbot from '../components/Chatbot';
import Awards from '../components/Home/Awards';

function Home() {
  return (
    <>
      <Helmet>
        <title>Zasta Infotek: Driving Digital Transformation in India | Chennai</title>
        <meta
          name="description"
          content="Explore cutting-edge technology solutions at Zasta Infotek. We specialize in software development, IT consulting, and digital transformation services. Empower your business with our innovative and reliable technology solutions. Based in India, serving clients in Chennai and beyond."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Technology Solutions, Software Development, IT Consulting, Digital Transformation, Innovation Services, India, Chennai"
        />
      </Helmet>


      <Chatbot />
      <Header />

      {/* <h1>
          <div className="started pt-5"  data-aos="fade-down" data-aos-duration="1500">Welcome to Zasta Infotek Private Limited </div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in" data-aos-duration="1500">
          Elevating Businesses through Innovative Technology Solutions
          </div>
        </h2>
       */}
 
      <HomeBanner />
       <VisionStatement />
  
      
     <Technology />
      <WeDo />
      <Awards />
      <Clients />
      <div></div>
      <Footer />
    </>
  );
}

export default Home;
