import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Chatbot from '../components/Chatbot';
import WebComponent from '../components/Web/Web';
import Footer from '../components/Footer';

function Web() {
  return (
    <>
      <Helmet>
        <title>Zasta Infotek: Expert Web Development Services</title>
        <meta
          name="description"
          content="Discover Zasta Infotek's professional web development services. We specialize in creating cutting-edge, responsive, and user-friendly websites to enhance your online presence."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Web Development, Responsive Design, User-Friendly Websites, Professional Web Services"
        />
      </Helmet>

      <Chatbot />
      <Header />
      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Expert Web Development Services</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Discover Cutting-Edge, Responsive, and User-Friendly Websites with Zasta Infotek
          </div>
        </h2>
      
      
      <WebComponent />
      <Footer />
    </>
  );
}

export default Web;
