import React from "react";
import './Digital.css';
import { Link } from 'react-router-dom';
import seomain from '../../assets/images/main-seo.svg';
import support from '../../assets/images/support.svg';
import cro from '../../assets/images/cro.svg';
import seosocial from '../../assets/images/seo-social.svg';
import fb from '../../assets/images/fb-marketing.svg';
import social from '../../assets/images/social.svg';
import insta from '../../assets/images/insta-marketing.svg';
import twit from '../../assets/images/twitter-marketing.svg';
import ppc from '../../assets/images/ppc.svg';
import contentmarketing from '../../assets/images/content-marketing.svg';
import youtube from '../../assets/images/youtube-marketing.svg';
import art from '../../assets/images/art-marketing.svg';
import poster from '../../assets/images/post-marketing.svg';
import copy from '../../assets/images/copy-marketing.svg';
import emailmarketing from '../../assets/images/email-marketing.svg';
import video from '../../assets/images/video-marketing.svg';
import ads from '../../assets/images/ads-marketing.svg';
import cookies from '../../assets/images/cookies-marketing.svg';
import media from '../../assets/images/media-marketing.svg';
import search from '../../assets/images/search-marketing.svg';
import framework from '../../assets/images/framework.svg';
const Digital = () =>{

 
  return (
    <div className="App">
      <div class="container-fluid navy-blue">
        <div class="container">
          <div class="row pt-5">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="web-design mt-5" data-aos="fade-right" data-aos-duration="1000">Digital Marketing</div>
              <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">Transform your online presence and drive business growth with our comprehensive digital marketing solutions. Contact us today to discuss your project requirements and embark on a journey to digital success with Us</div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-5">
                  <Link to="/contact-us"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Talk to Us</button></Link>
                  </div>
                </div>
                {/* <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-5">
                    <a href="#"><button class="learn-more-button" data-aos="fade-up" data-aos-duration="1500">Learn More</button></a>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div data-aos="fade-down" data-aos-duration="1500">
              <img src={seomain} class="w-100 mt-5 e-commerce-main" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid pb-5">
        <div class="container">
          <div class="started pt-5">Experience the Power of Digital Marketing</div>
          <div class="start-description pt-3">Your trusted partner for comprehensive digital marketing solutions. We are dedicated to helping businesses thrive in the digital landscape by driving targeted traffic, increasing brand visibility, and maximizing conversions. With our expertise in digital marketing strategies and a focus on delivering measurable results, we provide tailored solutions to meet your specific needs.
          </div>
          <div class="container-fluid pb-5">
        <div class="container">
          <div class="row mt-5">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={seosocial} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Search Engine Optimization (SEO)</div>
                  <div class="feature-description pt-2">Our SEO experts employ proven techniques to optimize your website for search engines and improve its visibility in organic search results. Through keyword research, on-page optimization, technical SEO, and link building, we help your website rank higher, attract more organic traffic, and generate quality leads.</div>
              
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={ppc} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Pay-Per-Click Advertising (PPC)</div>
                  <div class="feature-description pt-2">Maximize your online reach and drive instant traffic with our strategic PPC campaigns. We create compelling ad copies, perform keyword research, and optimize your campaigns to achieve maximum return on investment (ROI) across platforms like Google Ads, Bing Ads, and social media advertising.</div>
               
                </div>
                </div>
              </a>
            </div>
            
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={social} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Social Media Marketing</div>
                  <div class="feature-description pt-2">Harness the power of social media platforms to engage your target audience and build a strong brand presence. Our social media experts develop data-driven strategies, create engaging content, manage social media campaigns, and analyze performance to drive engagement, increase followers, and generate leads.</div>
                 
                </div>
</div>
              </a>
            </div>

          </div>

          <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={contentmarketing} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Content Marketing</div>
                  <div class="feature-description pt-2">Create valuable and relevant content that resonates with your audience and establishes your brand as an industry authority. Our content marketing specialists develop content strategies, create compelling blog posts, articles, videos, and infographics, and distribute them through various channels to attract, engage, and retain your target audience.</div>
              
                </div>
</div>
              </a>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={emailmarketing} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Email Marketing</div>
                  <div class="feature-description pt-2">Nurture and convert leads with effective email marketing campaigns. We design eye-catching email templates, segment your audience, and create personalized email sequences to deliver targeted messages, drive engagement, and encourage conversions. We also track email performance and optimize campaigns for maximum effectiveness.</div>
              
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-right" data-aos-duration="2000">
                <div><img src={cro} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Conversion Rate Optimization (CRO)</div>
                  <div class="feature-description pt-4"> Optimize your website and marketing assets to maximize conversions. Our CRO experts analyze user behavior, conduct A/B testing, and implement data-driven optimizations to improve user experience, increase conversion rates, and maximize the ROI of your digital marketing efforts.</div>
                  
                </div>
</div>
              </a>
            </div>

          </div>
        </div>
      </div>
        </div>
      </div>
      <div class="container-fluid bg-color">
      <div class="container">
      <div class="started pt-5">What do we offer?
                </div>
          <div class="start-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for
                        any businesses, found during the<br/> year 2019 to provide customized and efficient software .
          </div>
<div class="row">
<div class="col-lg-4 col-md-4 col-sm-12 mt-5">
                    <div class="marketing-content-box" data-aos="flip-up" data-aos-duration="1500">
                        <div class="center">
                            <img src={support} class="mt-3"/>
                        </div>
                        <div class="marketing-content text-center mt-3">Daily Social Media Management</div>
                        <hr class="line mt-3"/>
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                                <div class="flex mt-3 icon-space">
                                    <img src={fb}/>
                                    <div class="icon-content">&nbsp;Facebook</div>
                                </div>
                                <div class="flex mt-4">
                                    <img src={youtube}/>
                                    <div class="icon-content">&nbsp;Youtube</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                                <div class="flex mt-3 icon-space-instagram">
                                    <img src={insta}/>
                                    <div class="icon-content">&nbsp;Instagram</div>
                                </div>
                                <div class="flex mt-4">
                                    <img src={twit}/>
                                    <div class="icon-content">&nbsp;Twitter</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 mt-5">
                    <div class="marketing-content-box" data-aos="flip-up" data-aos-duration="1500">
                    <div class="center">
                            <img src={support} class="mt-3"/>
                        </div>
                        <div class="marketing-content text-center mt-3">Content Solution</div>
                        <hr class="line mt-3"/>
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                                <div class="flex mt-3 icon-space">
                                    <img src={art}/>
                                    <div class="icon-content">&nbsp;Art work Design</div>
                                </div>
                                <div class="flex mt-4">
                                    <img src={poster}/>
                                    <div class="icon-content">&nbsp;Poster Design</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                                <div class="flex mt-3">
                                    <img src={copy}/>
                                    <div class="icon-content">&nbsp;Copy Writing</div>
                                </div>
                                <div class="flex mt-4 icon-space-video">
                                    <img src={video}/>
                                    <div class="icon-content">&nbsp;Video production</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm- mt-5">
                    <div class="marketing-content-box" data-aos="flip-up" data-aos-duration="1500">
                    <div class="center">
                            <img src={support} class="mt-3"/>
                        </div>
                        <div class="marketing-content text-center mt-3">Social Media Ads</div>
                        <hr class="line mt-3"/>
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                                <div class="flex mt-3 icon-space-ads">
                                    <img src={ads}/>
                                    <div class="icon-content">&nbsp;Google Ads</div>
                                </div>
                                <div class="flex mt-4">
                                    <img src={cookies}/>
                                    <div class="icon-content">&nbsp;Cookies</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 mt-3">
                                <div class="flex mt-3">
                                    <img src={media}/>
                                    <div class="icon-content">&nbsp;Social Media Boosting</div>
                                </div>
                                <div class="flex mt-4">
                                    <img src={search}/>
                                    <div class="icon-content">&nbsp;Google search bidding</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>
      </div>
      </div>

    </div>
  );
}

export default Digital;
