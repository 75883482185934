import React from 'react';
import react from '../../assets/images/logo-react.svg';
import next from '../../assets/images/logo-next.png';
import ionic from '../../assets/images/ionic.svg';
import angular from '../../assets/images/logo-angular.svg';
import js from '../../assets/images/logo-js.svg';
import php from '../../assets/images/logo-php.svg';
import python from '../../assets/images/logo-python.svg';
import java from '../../assets/images/logo-java.svg';
import express from '../../assets/images/logo-express.svg';
import sql from '../../assets/images/logo-sql.svg';
import mango from '../../assets/images/logo-mangodb.svg';
import aws from '../../assets/images/logo-aws.svg';
import node from '../../assets/images/logo-nodejs.svg';
import flutter from '../../assets/images/logo-flutter.svg';
import native from '../../assets/images/logo-native.svg';
import code from '../../assets/images/logo-code.svg';

export const Technology = () => {
  return (
    <div class="container-fluid bg-color mt-5">
      <div class="started pt-5">Technology We Use</div>
      <div class="start-description pt-3">At a high level, digital marketing refers to advertising
        delivered through digital channels<br /> such as search engines, websites, social media, email, and mobile
        apps.
      </div>
      <div class="row mt-4">
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={react} class="center" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">React Js</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={next} class="center next-img" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">Next Js</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={ionic} class="center ionic-img" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Ionic</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={angular} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Angular Js</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={js} class="center js-img" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">Js</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={php} class="center php-img" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">Php</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={python} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Python</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box mobile-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={java} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Java</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={express} class="center" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">Express Js</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={sql} class="center" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">MySQL</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box ">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={mango} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Mango DB</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={aws} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">AWS</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={node} class="center" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">Node Js</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="catagory-box">
            <div data-aos="zoom-in" data-aos-duration="1500">
              <img src={flutter} class="center" alt='technology' />
            </div>
            <div class="mob-app mt-2 center">Flutter</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={native} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Native</div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div class="mobile-box">
            <div class="catagory-box">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <img src={code} class="center" alt='technology' />
              </div>
              <div class="mob-app mt-2 center">Codeigniter</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
