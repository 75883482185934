import React from "react";
import './Mobile.css';
import { Link}from 'react-router-dom';
import mobilemain from '../../assets/images/main-mobile.svg';
import java from '../../assets/images/java.svg';
import flutter from '../../assets/images/flutter.svg';
import mobileimg from '../../assets/images/mobile-img.svg';
import react from '../../assets/images/react.svg';
import ionic from '../../assets/images/ionic.svg';
import cross from '../../assets/images/cross.svg';
import art from '../../assets/images/art.svg';
import ui from '../../assets/images/ui.svg';
import quality from '../../assets/images/quality.svg';
import native from '../../assets/images/native.svg';
import api from '../../assets/images/api-testing.svg';
import custom from '../../assets/images/custom.svg';
import experience from '../../assets/images/experience.svg';
import frontend from '../../assets/images/frontend.svg';
import framework from '../../assets/images/framework.svg';
const Mobile = () =>{


 
  return (
    <div className="App">
     <div class="container-fluid navy-blue">
   <div class="container">
     <div class="row pt-5">
       <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="web-design mt-5" data-aos="fade-right" data-aos-duration="1000">Mobile Application Development</div>
        <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">your reliable partner for mobile app development services. We specialize in creating cutting-edge mobile applications that empower businesses to engage their target audience, boost productivity, and drive revenue growth. With our expertise in mobile technologies and a focus on delivering exceptional user experiences.</div>
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-6 col-6">
            <div class="mt-4 mb-2">
            <Link to="/contact-us"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Talk to Us</button></Link>
          </div>
          </div>
          {/* <div class="col-lg-5 col-md-5 col-sm-6 col-6">
            <div class="mt-5">
              <a href="#"><button class="learn-more-button" data-aos="fade-up" data-aos-duration="1500">Learn More</button></a>
          </div>
          </div> */}
        </div>
       </div>
       <div class="col-lg-6 col-md-12 col-sm-12">
        <div data-aos="fade-down" data-aos-duration="1500">
        <img src={mobilemain} class="w-100 mt-5 e-commerce-main" alt="mobile"/>
        </div>
      </div>
     </div>
   </div>
  </div>
  <div class="container">
  <div class="started pt-5">What We do?</div>
      <div class="start-description pt-3">The globe revolve around internet</div>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="heading mt-5">Mobile App Development</div>
            <div class="sub-heading mt-4">Unlock the potential of mobile technology and elevate your business with our professional mobile app development services. Contact us today to discuss your project requirements and embark on a journey to digital success with Us.
            </div>

      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <img src={mobileimg} class="center w-100 mt-5" alt="mobile"></img>
        </div>
    </div>
  </div>
  <div class="container">
          <div class="started pt-5">Technology We Use</div>
          {/* <div class="start-description pt-3">At a high level, digital marketing refers to advertising
                    delivered through digital channels<br/> such as search engines, websites, social media, email, and mobile
                    apps.
          </div> */}
          <div class="row mt-4">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div data-aos="zoom-in" data-aos-duration="1500"><img src={java} class="center" alt="mobile" /></div>
          <div class="mob-app mt-3 center">Java</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div data-aos="zoom-in" data-aos-duration="1500"><img src={flutter} class="center" alt="mobile"/></div>
          <div class="mob-app mt-4 center">Flutter</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div data-aos="zoom-in" data-aos-duration="1500"><img src={react} class="center img-space" alt="mobile"/></div>
          <div class="mob-app mt-3 center">React native</div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6">
          <div data-aos="zoom-in" data-aos-duration="1500"><img src={ionic} class="center img-space" alt="mobile" /></div>
          <div class="mob-app mt-4 center">Ionic</div>
          </div>
          </div>
        </div>


        <div class="container-fluid pb-5">
        <div class="container">
          <div class="started pt-5">Our Mobile App Development Services</div>
          <div class="start-description pt-3">Our Unique features for you and your business enhancements. </div>
          <div class="row mt-5">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={native} class="mt-1" alt="mobile"/></div>
                  <div class="feature-heading mt-2">Native App Development</div>
                  <div class="feature-description pt-2">We harness the power of native app development to deliver high-performance, feature-rich applications for iOS and Android platforms. Our native apps are built using platform-specific technologies, ensuring seamless integration with device capabilities for optimal performance and user experience.</div>
              
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={cross} class="mt-1" alt="mobile" /></div>
                  <div class="feature-heading mt-2">Cross-Platform App Development</div>
                  <div class="feature-description pt-2">Reach a wider audience with our cross-platform app development solutions. We utilize frameworks like React Native and Flutter to develop apps that run smoothly on multiple platforms while maintaining a native-like experience. This approach offers cost-effective and time-efficient solutions without compromising on quality.</div>
                 
                </div>
</div>
              </a>
            </div>
            
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={custom} class="mt-1" alt="mobile" /></div>
                  <div class="feature-heading mt-2">Custom App Development</div>
                  <div class="feature-description pt-2">Our experienced team of developers specializes in building custom mobile applications tailored to your unique requirements. We collaborate closely with you to understand your business goals, target audience, and desired features, ensuring that the app aligns perfectly with your vision.</div>
                 
                </div>
</div>
              </a>
            </div>

          </div>

          <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={ui} class="mt-1" alt="mobile" /></div>
                  <div class="feature-heading mt-2">UI/UX Design</div>
                  <div class="feature-description pt-2">We create intuitive and visually captivating user interfaces (UI) combined with exceptional user experiences (UX). Our designers focus on usability, simplicity, and aesthetics to deliver interfaces that engage users and enhance their interactions with your mobile app.</div>
               
                </div>
</div>
              </a>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={api} class="mt-1" alt="mobile"/></div>
                  <div class="feature-heading mt-2">App Integration and API Development</div>
                  <div class="feature-description pt-2">We seamlessly integrate your mobile app with existing systems, third-party services, and APIs to enhance functionality and provide a cohesive user experience. Whether it's integrating payment gateways, social media platforms, or other business tools, we ensure smooth data exchange and interoperability.</div>
                 
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-right" data-aos-duration="2000">
                <div><img src={experience} class="mt-1" alt="mobile"/></div>
                  <div class="feature-heading mt-2">App Testing and Quality Assurance</div>
                  <div class="feature-description pt-2"> Quality is paramount to us. We conduct rigorous testing throughout the app development lifecycle to ensure bug-free, stable, and reliable applications. Our QA experts perform comprehensive testing on multiple devices and platforms to guarantee a seamless user experience across various scenarios.</div>
                 
                </div>
</div>
              </a>
            </div>

          </div>
        </div>
      </div>


</div>




  );
}

export default Mobile;
