import React from 'react';
import './StaffingSolutions.css';
import { Link}from 'react-router-dom';
import staffmain from '../../assets/images/main-staff.svg';
import talent from '../../assets/images/talent.svg';
import staff from '../../assets/images/stafing.png';
import efficiency from '../../assets/images/efficiency.svg';
import expertise from '../../assets/images/expertise.svg';
import flexible from '../../assets/images/flexible.svg';
import partner from '../../assets/images/partner.svg';
import experience from '../../assets/images/experience.svg';

const StaffingSolutions = () => {


  return (
    <div className="App">
     <div class="container-fluid navy-blue">
   <div class="container">
     <div class="row pt-5">
       <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="web-design mt-5" data-aos="fade-right" data-aos-duration="1000">Staffing solutions</div>
        <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">Contact us today to discuss your staffing requirements and embark on a partnership that will fuel your success.Zasta Infotek  – Your Partner in Staffing Solutions.</div>
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-6 col-6">
            <div class="mt-5">
            <Link to="/contact-us"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Talk to Us</button></Link>
          </div>
          </div>
          {/* <div class="col-lg-5 col-md-5 col-sm-6 col-6">
            <div class="mt-5">
              <a href="#"><button class="learn-more-button" data-aos="fade-up" data-aos-duration="1500">Learn More</button></a>
          </div>
          </div> */}
        </div>
       </div>
       <div class="col-lg-6 col-md-12 col-sm-12">
        <div data-aos="fade-down" data-aos-duration="1500">
        <img src={staffmain} class="w-100 mt-5 e-commerce-main"/>
        </div>
      </div>
     </div>
   </div>
  </div>
  <div class="container">
  <div class="started pt-5">What We do?</div>
      <div class="start-description pt-3">The globe revolve around internet</div>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="heading mt-5">Staffing Solutions</div>
            <div class="sub-heading mt-4">Welcome to Zasta Infotek, your premier destination for comprehensive staffing solutions. We are dedicated to helping businesses like yours thrive by connecting you with top-tier talent that drives success. With our expertise and personalized approach, we ensure you have the right people in the right positions to achieve your goals.
            </div>

      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <img src={staff} class="center w-100 mt-5"></img>
        </div>
    </div>
  </div>


        <div class="container-fluid pb-5">
        <div class="container">
          <div class="started pt-5">Our Staffing Services</div>
          <div class="start-description pt-3">We understand that every business has unique staffing needs. That's why we take the time to listen and understand your requirements, culture, and objectives. Whether you're looking for temporary, contract, or permanent placements, we tailor our solutions to match your specific needs. </div>
          <div class="row mt-5">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
                <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={talent} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Unparalleled Talent Network</div>
                  <div class="feature-description pt-2">Tap into our extensive talent network consisting of highly skilled professionals across various industries. We have a rigorous screening process in place to ensure we only present you with candidates who possess the right qualifications, experience, and cultural fit. Our commitment to quality means you can trust us to deliver exceptional talent that meets your standards.</div>
                  
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={flexible} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Flexible Staffing Options</div>
                  <div class="feature-description pt-2">We believe in providing flexible staffing options to support your changing business demands. Whether you require short-term support for a specific project or long-term placements to enhance your core team, we have you covered. Our solutions are designed to provide agility and scalability so that you can adapt to market dynamics with ease.</div>
                
                </div>
</div>
              </a>
            </div>
            
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={efficiency} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Efficiency and Speed</div>
                  <div class="feature-description pt-2">We understand that time is crucial when it comes to hiring. Our streamlined recruitment processes and proactive approach enable us to provide efficient and timely staffing solutions. We work diligently to ensure quick turnaround times without compromising on quality, allowing you to focus on your core business operations.</div>
                 
                </div>
</div>
              </a>
            </div>

          </div>

          <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box margin" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={expertise} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Industry Expertise</div>
                  <div class="feature-description pt-2">With our team of industry experts, we possess in-depth knowledge and understanding of various sectors. We stay updated with market trends, regulatory changes, and emerging technologies, ensuring that we can identify candidates who not only have the necessary skills but also align with the specific requirements of your industry.</div>
                
                </div>
</div>
              </a>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box margin" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={partner} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Long-Term Partnerships</div>
                  <div class="feature-description pt-2">At Zasta Infotek, we believe in building long-term partnerships with our clients. We strive to become an extension of your team, working closely with you to address your current and future staffing needs. Our dedication to your success means we are committed to providing ongoing support and finding solutions that evolve with your business.</div>
              
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="feature-box margin" data-aos="flip-right" data-aos-duration="2000">
                <div><img src={experience} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Experience the Difference:</div>
                  <div class="feature-description pt-2"> Discover the benefits of working with a trusted staffing solutions partner. Experience our personalized approach, access to exceptional talent, and commitment to excellence. Let us handle the complexities of staffing while you focus on what you do best – growing your business.</div>
                 
                </div>
</div>
              </a>
            </div>

          </div>
        </div>
      </div>


</div>




  );
}

export default StaffingSolutions;
