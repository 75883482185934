import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import MobileComponent from '../components/Mobile/Mobile';
import Footer from '../components/Footer';
import Chatbot from '../components/Chatbot';

function Mobile() {
  return (
    <>
      {/* Uncomment the line below if you want to include the Chatbot */}
      {/* <Chatbot /> */}
      
      <Header />
      <Helmet>
        <title>Zasta Infotek: Premier Mobile App Development in India, Chennai</title>
        <meta
          name="description"
          content="Explore Zasta Infotek's expertise in mobile app development. We create innovative and user-friendly mobile applications tailored to meet your business needs. Based in India, serving clients in Chennai and beyond."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Mobile App Development, iOS App Development, Android App Development, Cross-Platform Apps, Mobile Application Solutions, India, Chennai"
        />
      </Helmet>
      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Premier Mobile App Development in India, Chennai</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Explore Innovative and User-Friendly Mobile Applications with Zasta Infotek
          </div>
        </h2>
      
     
      <MobileComponent />
      <Footer />
    </>
  );
}

export default Mobile;
