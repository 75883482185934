import React, { useState,useEffect } from "react";
import chat from '../assets/images/chat.svg';
import imgmodal from '../assets/images/imgmodal.png';
import emailjs from "emailjs-com";
import { db } from '../firebase';

const Chatbot = () => {

  
  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_y18yjho','template_lvcz3d7',e.target,"tPVKUKbHFllq5r78n").then(res=>{
       console.log(res);
    }).catch(err=>console.log(err));
   }

const [name, setName] = useState("");
const [mail, setEmail] = useState("");
const [mobile, setNumber] = useState("");
const [message, setMessage] = useState("");


const handleSubmit = (e) => {
    sendEmail(e)
  e.preventDefault();

  db.collection("details")
    .add({
      name: name,
      mail: mail,
      mobile: mobile,
      message: message,
    })

  setName("");
  setEmail("");
  setNumber("");
  setMessage("");
};
    const [formVisible, setFormVisible] = useState(false);

    const openForm = () => {
      setFormVisible(true);
    };
  
    const closeForm = () => {
      setFormVisible(false);
    };
    const [test, Settest] = useState(false);
    console.log("testing test", test);
    useEffect(() => {
      if (
        mail.length === 0 ||
        name.length === 0 ||
        mobile.length === 0 ||
        message.length == 0
      ) {
        Settest(false);
      }
      if (
          mail.length &&
          name.length &&
          mobile.length &&
          message.length 
        ) {
          Settest(true);
        }
    });
  return (
    <div>
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       <div class="modal-box">
       <img src={imgmodal} class="w-100"/>
       </div>
       <div class="thanks mt-4">Thank you for contacting us!</div>
       <div class="modal-txt mt-4">Your submission has been received,<br/>We will be in touch and our team will reach you out soon</div>
      </div>
      <div class="modal-footer">
      <button class="submit-button" data-bs-dismiss="modal" aria-label="Close">Back to Site</button>
      </div>
    </div>
  </div>
</div>
    <div className="float" target="_blank" onClick={openForm}>
    <div><img src={chat} class="bot" alt="chat" /></div>
    </div>
           {/* Popup Form */}
           {formVisible && (
        <div
          className="popupForm"
          style={{
            position: "fixed",
            bottom: "80px",
            right: "60px",
            width: "300px",
            padding: "10px",
            background:"white",
            borderRadius: "5px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
            zIndex: 9999,
          }}
        >
          {/* Form content */}
          <form onSubmit={handleSubmit}>
          <div class="req-chat-field mt-4"> Your Name</div>
            <input type="text" class="mt-2"  value={name} name="name"
          onChange={(e) => setName(e.target.value)}
         required/>
            <div class="req-chat-field mt-4"> Your E-mail Id</div>
            <input type="text" class="mt-2"  value={mail} name="mail"
        onChange={(e) => setEmail(e.target.value)}
         required/>
            <div class="req-chat-field mt-4"> Your Mobile number</div>
            <input type="text" class="mt-2"  value={mobile} name="mobile"
        onChange={(e) => setNumber(e.target.value)}
         required/>
            {/* <div class="req-chat-field mt-4">What do you need ?</div>
            <div class="flex-checkbox mt-2">
                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                            id="radioNoLabel1" value="" aria-label=""/>
                        <div class="needs-chat mt-2">&nbsp; Web Development</div>
                    </div>
                    <div class="flex-checkbox mt-2">
                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                            id="radioNoLabel1" value="" aria-label=""/>
                        <div class="needs-chat mt-2">&nbsp; Graphic Design</div>
                    </div>
                    <div class="flex-checkbox mt-2">
                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                            id="radioNoLabel1" value="" aria-label=""/>
                        <div class="needs-chat mt-2">&nbsp; Mobile App development</div>
                    </div>
                    <div class="flex-checkbox mt-2">
                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                            id="radioNoLabel1" value="" aria-label=""/>
                        <div class="needs-chat mt-2">&nbsp; Others</div>
                    </div> */}
                    <div class="req-chat-field pt-4">Message</div>
            <input type="text" placeholder="write your messages here..." class="mt-3"  name="message" value={message}
        onChange={(e) => setMessage(e.target.value)}
         required/>
         {test?( <button class="submit-button-chat mt-3" data-bs-toggle="modal" data-bs-target="#exampleModal">Send</button>):(
           <button class="submit-button-chat mt-3" >Send</button>
         )}
           
          <button class="close-button-chat mt-2" onClick={closeForm} >Close Chat</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
