import React from 'react';
import l1 from '../../assets/images/l1.avif';
import l2 from '../../assets/images/l2.avif';
import l3 from '../../assets/images/l3.avif';
import l4 from '../../assets/images/l4.avif';
import l5 from '../../assets/images/l5.avif';
import l6 from '../../assets/images/l6.avif';
import l7 from '../../assets/images/l7.avif';
import l8 from '../../assets/images/l8.avif';
import l9 from '../../assets/images/l9.avif';
import l10 from '../../assets/images/l10.avif';


function Clients() {
  return (
    <div class="container-fluid">
    <div class="started pt-5">Companies trusting us</div>
   <div class="slider mt-5 mb-5">
    <div class="slider-track">
        <div>
        <img src={l1} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l2} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l3} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l4} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l5} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l6} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l7} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l8} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l9} class="slide" alt='client'/>
        </div>
        <div>
        <img src={l10} class="slide" alt='client'/>
        </div>
    </div>
   </div>
  </div>
  )
}
export default Clients;