
import React from 'react';
import person6 from '../../assets/images/person-6.svg';
import person2 from '../../assets/images/person-2.svg';
import person3 from '../../assets/images/person-3.svg';
import person4 from '../../assets/images/person-4.svg';
import person5 from '../../assets/images/person-5.svg';
import story from '../../assets/images/story.svg';
import approach from '../../assets/images/approach.svg';
import expert from '../../assets/images/expert.svg';
import clients from '../../assets/images/clients.svg';
import discussion from '../../assets/images/discussion.svg';
import works from '../../assets/video/works.mp4';
import { Link } from 'react-router-dom';
import './About.css';
function About() {
  return (
    <div className="App">
      <div class="container-fluid navy-blue pt-3">
        <section id="testimonial-area">
          <div class="container">
            <div class="testi-wrap">
              <div class="client-single active position-1" data-position="position-1">
                <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  {/* <img src={person6} alt="person" /> */}
                </div>
                {/* <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  <img src={person6} alt="" />
                </div> */}
                <div class="zasta-about" data-aos="zoom-in" data-aos-duration="1500">
                  Team<br />Behind Us
                </div>
                <div class="about-description pt-2" data-aos="zoom-in" data-aos-duration="1500">We are a team of passionate technologists, designers, and problem-solvers dedicated to crafting cutting-edge solutions for businesses worldwide.</div>
              </div>
              {/* <div class="client-single inactive position-2" data-position="position-2">
                <div class="client-img" data-aos="fade-up" data-aos-duration="2000">
                  <img src={person2} alt="person" />
                </div>
              </div> */}
              {/* <div class="client-single inactive position-3" data-position="position-3">
                <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  <img src={person3} alt='person' />
                </div>
              </div> */}
              {/* <div class="client-single inactive position-5" data-position="position-5">
                <div class="client-img" data-aos="fade-up" data-aos-duration="2000">
                  <img src={person4} alt="person" />
                </div>
              </div> */}
              {/* <div class="client-single inactive position-6" data-position="position-6">
                <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  <img src={person5} alt="person" />
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
      <div class="container-fluid pb-5">
        <div class="container">
          <div class="started pt-5">HOW WE STARTED</div>
          <div class="start-description pt-3">Welcome to Zasta Infotek Private Limited, where innovation meets excellence in software development.<br/> We are a team of passionate technologists, designers, and problem-solvers dedicated to crafting cutting-edge solutions for businesses worldwide. </div>
          <div class="video-wrapper mt-5">
            <video controls class="video" poster={discussion}>
              <source src={works} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="container">
        <div class="row">
               <div class="col-lg-6 col-md-12 col-sm-12">
               <div class="mobile-box">
                <div class="us-box mt-2 mb-2" data-aos="flip-left" data-aos-duration="1000">
                <img src={story} class="about-content-img" alt='story'/>
                        <div class="about-us-heading mt-3">Our Story</div>  
                        <div class="about-us-content mt-2">At Zasta Infotek Private Limited, our journey began with a shared vision to revolutionize the digital landscape through transformative software solutions. Founded by a group of industry experts, our company has grown from humble beginnings into a powerhouse of innovation.</div> 
                </div>
</div>
               </div>
               <div class="col-lg-6 col-md-12 col-sm-12">
               <div class="mobile-box">
                <div class="us-box mt-2 mb-2" data-aos="flip-right" data-aos-duration="1000">
                <img src={approach} class="about-content-img" alt='approach'/>
                        <div class="about-us-heading mt-2">Our Approach</div>  
                        <div class="about-us-content mt-2">We believe that great software is not just about lines of code; it's about understanding your unique challenges and providing tailored solutions that drive your success. Our approach is centered around collaboration, transparency, and a deep understanding of your business goals. We immerse ourselves in your world to deliver intuitive and scalable software that exceeds your expectations.</div> 
                 </div> 
                </div>  
            </div>
           </div>
           <div class="row mt-2 mb-4">
               <div class="col-lg-6 col-md-12 col-sm-12">
               <div class="mobile-box">
                <div class="us-box mt-2 mb-2" data-aos="flip-left" data-aos-duration="1000">
                <img src={expert} class="about-content-img" alt='expert'/>
                        <div class="about-us-heading mt-3">Our Expertise</div>  
                        <div class="about-us-content mt-2">With years of experience in the industry, we have honed our skills across a wide range of technologies and platforms. From web and mobile applications to enterprise software and cloud solutions, our expertise spans the entire software development lifecycle. Our team is proficient in the latest frameworks, languages, and tools, ensuring that we stay ahead of the curve and deliver future-proof solutions.</div> 
                </div>
</div>
               </div>
               <div class="col-lg-6 col-md-12 col-sm-12">
               <div class="mobile-box">
                <div class="us-box mt-2 mb-2" data-aos="flip-right" data-aos-duration="1000">
                <img src={clients} class="about-content-img" alt='clients'/>
                        <div class="about-us-heading mt-2">Our Clients</div>  
                        <div class="about-us-content mt-2">We have had the privilege of working with diverse clients across various industries, ranging from startups to large enterprises. Our portfolio showcases our ability to tailor solutions to specific business needs, delivering measurable results and empowering our clients to thrive in the digital age.</div> 
                 </div>  
</div>
            </div>
           </div>
        </div>
      </div>
      <div class="container-fluid navy-blue mt-5 pb-5">
            <div class="container">
                <div class="process pt-5">Why Chooses Us</div>

                <div class="row mt-5">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Technical Excellence</div>
                                <div class="catagory-description pt-2">Our team comprises skilled professionals who are passionate about their craft and dedicated to staying at the forefront of technology trends.</div>
                            </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                      <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Customized Solutions</div>
                                <div class="catagory-description pt-2"> We believe in the power of tailor-made solutions that align with your unique requirements, ensuring maximum efficiency and impact.
                                </div>
</div>                            </div>

                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Proven Track Record</div>
                                <div class="catagory-description pt-2">Our track record speaks for itself, with successful projects and satisfied clients who have witnessed the transformative power of our solutions.
                                </div>
                            </div>
                     </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Customer Experience</div>
                                <div class="catagory-description pt-2">We prioritize exceptional customer service, providing proactive support, timely communication, and a seamless development process.
                                </div>
                            </div>
</div>
                    </div>
                </div>
            </div>
        </div>
      <div class="container-fluid bg-yellow">
        <div class="container">
        <div class="about-contact-txt pt-5 pb-3" data-aos="fade-right" data-aos-duration="1000">Join us on the journey of digital transformation. Let's innovate together and turn your visions into reality.<br />Contact us today to explore how we can elevate your business to new heights through our bespoke software solutions.</div>
        <div class="pb-4">
                    <Link to="/contact-us"><button class="about-talk-button" data-aos="fade-up" data-aos-duration="1000">Talk to Us</button></Link>
                  </div>
        </div>
      </div>
      {/* <div class="container-fluid deep-blue pb-5">
        <div class="container">
          <div class="portfolio pt-5">MEET OUR TEAM</div>
          <div class="zasta-portfolio pt-3">Zasta Infotek Pvt Ltd is a Technology service provider<br />for any businesses
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 pt-5">
              <div class="nav flex-column nav-pills me-3 mt-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <button class="nav-link active v-nav-link active w-100" id="v-pills-high-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-high" type="button" role="tab" aria-controls="v-pills-high"
                  aria-selected="true">
                  <div class="d-flex">
                    <div><img src={billing} class="mt-1" /></div>
                    <div class="zasta-v">Founders</div>
                  </div>
                </button>
                <button class="nav-link v-nav-link w-100" id="v-pills-responsive-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-responsive" type="button" role="tab" aria-controls="v-pills-responsive"
                  aria-selected="false">
                  <div class="d-flex">
                    <div><img src={developer} class="mt-1" /></div>
                    <div class="zasta-v pt-1">Developers</div>
                  </div>
                </button>
                <button class="nav-link v-nav-link w-100" id="v-pills-quality-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-quality" type="button" role="tab" aria-controls="v-pills-quality"
                  aria-selected="false">
                  <div class="d-flex">
                    <div><img src={designer} class="mt-1" /></div>
                    <div class="zasta-v pt-1">Designers</div>
                  </div>
                </button>
                <button class="nav-link v-nav-link w-100" id="v-pills-social-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-social" type="button" role="tab" aria-controls="v-pills-social"
                  aria-selected="false">
                  <div class="d-flex">
                    <div><img src={marketing} class="mt-1" /></div>
                    <div class="zasta-v pt-1">Marketing</div>
                  </div>
                </button>
              </div>
            </div>
            <div class="col-lg-8 col-md-12 col-sm-12 pt-5 mt-5">
              <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-high" role="tabpanel"
                  aria-labelledby="v-pills-high-tab">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade mt-5" id="v-pills-responsive" role="tabpanel"
                  aria-labelledby="v-pills-responsive-tab">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade mt-5" id="v-pills-quality" role="tabpanel" aria-labelledby="v-pills-quality-tab">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade mt-5" id="v-pills-social" role="tabpanel" aria-labelledby="v-pills-social-tab">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="person-box second-box">
                        <img src={emp} class="center" />
                        <div class="person-name pt-2">Johaneses Gliberd</div>
                        <div class="person-role pt-2">CEO OF ZASTA Infotek</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid pb-5">
        <div class="container">
          <div class="join pt-5">BE A PART OF ZASTA JOIN<br />WITH US</div>
          <div class="join-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses,
            found during the<br />year 2019 to provide customized and efficient software . </div>
          <div class="row mt-5">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="vacancy-box" data-aos="fade-up" data-aos-duration="1500">
                <div class="row">
                  <div class="col-lg-2 col-md-2 col-sm-12">
                    <img src={file} class="center" />
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-12">
                    <div class="vacancy-position">Php Developer</div>
                    <div class="position-description pt-3">PHP, My-SQL Development & Support for diverse web based
                      applications. Must posses a team-work mentality. PHP, My-SQL Development & Support for diverse web
                      based applications. Must posses a team-work mentality.</div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <button class="apply-button mt-3">Apply Now</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="vacancy-box mt-3" data-aos="fade-up" data-aos-duration="2000">
                <div class="row">
                  <div class="col-lg-2 col-md-2 col-sm-12">
                    <img src={file} class="center" />
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-12">
                    <div class="vacancy-position">Full Stack Developer</div>
                    <div class="position-description pt-3">AngularJS/ReactJS, NodeJS, MongoDB, ExpressJS</div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <button class="apply-button mt-3">Apply Now</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-10">
              <div class="vacancy-box mt-3" data-aos="fade-up" data-aos-duration="2500">
                <div class="row">
                  <div class="col-lg-2 col-md-2 col-sm-12">
                    <img src={file} class="center" />
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-12">
                    <div class="vacancy-position">Java Developer</div>
                    <div class="position-description pt-3">Python, Django, Flask, Bottle, AWS, Rest API, Javascript, AngularJS, Mobile Applications.</div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <button class="apply-button mt-3">Apply Now</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default About;
