import { BrowserRouter as Router, Route, Link, NavLink, Switch } from 'react-router-dom';
import home from '../../assets/images/zasta-home.png';
import infotek from '../../assets/video/zasta-infotek.mp4';
import catagory from '../../assets/images/new.svg';
import app from '../../assets/images/app.svg';
import marketing from '../../assets/images/marketing-main.svg';
import consulting from '../../assets/images/consulting.svg';
import ux from '../../assets/images/ux.svg';
import webcustom from '../../assets/images/web-custom.svg';
import '../../App.css';
import React from 'react';

function WeDo() {
    return (
        <div class="container-fluid pb-5">
            <div class="container">
                <div class="started pt-5">WHAT WE DO</div>

                <div class="row mt-5">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <Link to="/web-development" class="decoration">
                            <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                                <div><img src={webcustom} class="mt-1" alt='catagory' /></div>
                                <div class="feature-heading mt-3">Custom Software Development</div>
                                <div class="feature-description pt-2">TWe specialize in developing tailored software solutions that align with your specific needs. From enterprise-grade applications to web and mobile apps, we leverage the latest technologies and best practices to deliver robust, scalable, and secure software products.</div>

                            </div>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <Link to="/Ecommerce" class="decoration">
                            <div class="mobile-box">
                                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                                    <div><img src={catagory} class="mt-1" alt='catagory' /></div>
                                    <div class="feature-heading mt-3">Web Development</div>
                                    <div class="feature-description pt-2">Our web development services encompass everything from website design and development to e-commerce solutions and content management systems. We create visually stunning and user-friendly websites that enhance your online presence and drive engagement.
                                    </div>
                                </div>
                            </div>

                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <Link to="/mobile-development" class="decoration">
                            <div class="mobile-box">
                                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                                    <div><img src={app} class="mt-1" alt='catagory' /></div>
                                    <div class="feature-heading mt-3">Mobile App Development</div>
                                    <div class="feature-description pt-2">We develop high-performance mobile applications for iOS and Android platforms. Our mobile app solutions are designed to deliver seamless user experiences, leverage device capabilities, and drive user engagement and retention.
                                    </div>

                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <Link to="/Quality" class="decoration">
                            <div class="mobile-feature-box" data-aos="flip-right" data-aos-duration="2000">
                                <div><img src={ux} class="mt-1" alt='catagory' /></div>
                                <div class="feature-heading mt-3">UI/UX Design</div>
                                <div class="feature-description pt-2">We understand the importance of user-centric design in creating intuitive and visually appealing software interfaces. Our UI/UX designers craft captivating user experiences that ensure ease of use, accessibility, and aesthetic appeal.</div>

                            </div>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <Link to="/Staffing" class="decoration">
                            <div class="mobile-box">
                                <div class="mobile-feature-box" data-aos="flip-right" data-aos-duration="2000">
                                    <div><img src={consulting} class="mt-1" alt='catagory' /></div>
                                    <div class="feature-heading mt-3">Product Strategy and Consulting</div>
                                    <div class="feature-description pt-2">We offer product strategy and consulting services to help you shape your software vision and define a roadmap for success. Our team provides strategic insights, market analysis, and technology recommendations to guide your product development journey.</div>

                                </div>
                            </div>
                        </Link>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <Link to="/Digital" class="decoration">
                            <div class="mobile-box">
                                <div class="mobile-feature-box" data-aos="flip-right" data-aos-duration="2000">
                                    <div><img src={marketing} class="mt-1" alt='catagory' /></div>
                                    <div class="feature-heading mt-3">Digital Marketing</div>
                                    <div class="feature-description pt-2">We are dedicated to helping businesses thrive in the digital landscape by driving targeted traffic, increasing brand visibility, and maximizing conversions. With our expertise in digital marketing strategies and a focus on delivering measurable results, we provide tailored solutions to meet your specific needs</div>

                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WeDo;