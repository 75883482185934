import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import AboutUs from '../components/About/About';
import Footer from '../components/Footer';
import Chatbot from '../components/Chatbot';

function About() {
  return (
    <>
      <Helmet>
        <title>About Zasta Infotek: Innovation in Technology Solutions</title>
        <meta
          name="description"
          content="Discover the story of Zasta Infotek, a leading provider of innovative technology solutions. Learn about our commitment to excellence, our team of experts, and how we empower businesses through cutting-edge software development and digital services."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Innovation in Technology Solutions, Software Development Company, Digital Services Provider, Cutting-Edge Technology Solutions, Business Empowerment through Technology, About Zasta Infotek Team, Leading Innovators in Technology, Innovative Software Development, Zasta Infotek Story"
        />
      </Helmet>

      <Chatbot />
      <Header />

      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">About Zasta Infotek Private Limited</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Leading Innovators in Technology Solutions
          </div>
        </h2>
      
      
      <AboutUs />
      <Footer />
    </>
  );
}

export default About;
