import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import ContactComponent from '../components/Contact/Contact';
import Footer from '../components/Footer';
import Chatbot from '../components/Chatbot';

function Contact() {
  return (
    <>
      <Helmet>
        <title>Zasta Infotek: Get in Touch with Us</title>
        <meta
          name="description"
          content="Contact Zasta Infotek for inquiries, collaborations, or any questions you may have. We are here to assist you with our expertise in technology solutions, development, and digital services."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Contact Us, Get in Touch, Inquiry, Collaboration, Technology Solutions, Digital Services"
        />
      </Helmet>

      <Chatbot />
      <Header />

      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Contact Us</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Reach Out for Inquiries, Collaborations, and Expert Technology Solutions
          </div>
        </h2>
      
 
      
      <ContactComponent />
      <Footer />
    </>
  );
}

export default Contact;
