import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import StaffingSolutions from '../components/Staffing/StaffingSolutions';
import Footer from '../components/Footer';
import Chatbot from '../components/Chatbot';

function Staffing() {
  return (
    <>
      <Helmet>
        <title>Zasta Infotek: Strategic Staffing Solutions and Workforce Management</title>
        <meta
          name="description"
          content="Optimize your workforce with Zasta Infotek's strategic staffing solutions. We provide comprehensive workforce management services, helping businesses find the right talent and streamline their staffing processes."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Staffing Solutions, Workforce Management, Strategic Staffing, Talent Acquisition, Staffing Services, HR Solutions"
        />
      </Helmet>

      <Chatbot />
      <Header />
      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Strategic Staffing Solutions and Workforce Management</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Optimize Your Workforce with Zasta Infotek's Expertise
          </div>
        </h2>
      
      <StaffingSolutions />
      <Footer />
    </>
  );
}

export default Staffing;
