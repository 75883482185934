import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import QualityComponent from '../components/Quality/Quality';
import Footer from '../components/Footer';
import Chatbot from '../components/Chatbot';

function Quality() {
  return (
    <>
      <Helmet>
        <title>Zasta Infotek: Expert Quality Assurance and Testing Services</title>
        <meta
          name="description"
          content="Ensure the reliability and functionality of your software with Zasta Infotek's expert quality assurance and testing services. Our dedicated team ensures your applications meet the highest standards, providing a seamless experience for your users."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Quality Assurance, Software Testing, QA Testing, Quality Testing, Testing Services, Software Quality, Testing and Assurance"
        />
      </Helmet>

      <Chatbot />
      <Header />
      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Expert Quality Assurance and Testing Services</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Ensure Software Reliability and Functionality with Zasta Infotek
          </div>
        </h2>
      
     
      <QualityComponent />
      <Footer />
    </>
  );
}

export default Quality;
