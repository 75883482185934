import React from 'react';
import award from '../../assets/images/Award.svg';
import rate from '../../assets/images/rate.webp';


function Awards() {
    return (
        <div class="container-fluid bg-color">
            <div class="started pt-5">Awards and recognition</div>
            <div class="start-description pt-3">we take immense pride in our commitment to delivering exceptional services and innovative solutions.<br /> Over the years, our dedication to excellence has been recognized by esteemed organizations and industry leaders, making us a recipient of numerous prestigious awards and accolades.
            </div>
            <div class="row mt-4">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="catagory-box">
                    <a href="https://www.appfutura.com/app-developers/in/india/tamil-nadu/s/xa" target="_blank"><img width="60%" src="https://www.appfutura.com/img/badges/badge-top-app-company-tamil-nadu.png" alt='awards' class="center"/></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="catagory-box">
                    <img src={award} width="80%" class="center mt-5" alt='award' />
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="catagory-box">
                    <div><a target="_blank" href="https://itrate.co/web-developers/ecommerce"><img width="40%" src={rate} class="center mt-3" alt='award'/></a></div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="catagory-box">
                    <a href='https://www.softwaresuggest.com/zastabilling' target='_blank'><img src='https://www.softwaresuggest.com/award_logo/Best-Meet-requirement.png' width="60%" class="center mt-1" alt='award'/></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="catagory-box">
                    <a href='https://www.softwaresuggest.com/zastabilling' target='_blank'><img src='https://www.softwaresuggest.com/award_logo/Best-Value-Software-2022.png' width="65%" class="center" alt='award'/></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="catagory-box">
                    <a href='https://www.softwaresuggest.com/zastabilling' target='_blank'><img src='https://www.softwaresuggest.com/award_logo/best-support-2023.png' width="65%" class="center" alt='award'/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Awards;