import React from "react";
import main from '../../assets/images/main-img.svg';
import art from '../../assets/images/art.svg';
import hd from '../../assets/images/hd.svg';
import quality from '../../assets/images/quality.svg';
import frontend from '../../assets/images/frontend.svg';
import framework from '../../assets/images/framework.svg';
import responsive from '../../assets/images/responsive.svg';
import cms from '../../assets/images/cms.svg';
import ecom from '../../assets/images/e-com.svg';
import ui from '../../assets/images/ui.svg';
import overlap from '../../assets/images/overlap.jpg';
import webvideo from '../../assets/video/webdevelopment.mp4';
import maintain from '../../assets/images/maintain.svg';
import web from '../../assets/images/web.svg';
import web1 from '../../assets/images/web-1.svg';

import { Link } from 'react-router-dom';

import '../../App.css';
const Web = () => {
  
  return (
    <div className="App">
      <div class="container-fluid navy-blue">
        <div class="container">
          <div class="row pt-5">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="web-design mt-5" data-aos="fade-right" data-aos-duration="1000">Web Design & Development</div>
              <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">Transform your online presence and drive growth with our professional web design and development services. Contact us today to discuss your project requirements and embark on a journey to digital success with Zasta Infotek Private Limited</div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-5">
                  <Link to="/contact-us"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Talk to Us</button></Link>
                  </div>
                </div>
                {/* <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-5">
                    <a href="#"><button class="learn-more-button" data-aos="fade-up" data-aos-duration="1500">Learn More</button></a>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div data-aos="fade-down" data-aos-duration="1500">
                <img src={web1} class="w-100 mt-5 main-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="started pt-5">What We do?</div>
        {/* <div class="start-description pt-3">Our Web Design and Development Services:</div> */}
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="heading mt-3">Website Design & Development</div>
            <div class="sub-heading mt-4">Welcome to Zasta Infotek Private Limited, your trusted partner for professional web design and development services. We are dedicated to helping businesses establish a strong online presence and achieve their digital goals. With our expertise in web technologies and a focus on delivering exceptional user experiences, we provide comprehensive solutions tailored to your specific needs
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <img src={web} class="center w-100"></img>
          </div>
        </div>
      </div>
      <div class="container-fluid pb-5">
        <div class="container">
          <div class="started pt-5">Our Web Design and Development Services</div>
          <div class="start-description pt-3">Our Unique features for you and your business enhancements. </div>
          <div class="row mt-5">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
                <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={responsive} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Responsive Website Design</div>
                  <div class="feature-description pt-2">We create visually stunning and fully responsive websites that adapt seamlessly across all devices. Our designs are crafted to captivate your audience and leave a lasting impression, while ensuring optimal functionality and usability.</div>
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={framework} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Custom Web Development</div>
                  <div class="feature-description pt-2">Our team of skilled developers specializes in building custom web applications tailored to your unique requirements. We leverage the latest technologies and coding practices to deliver secure, scalable, and high-performing solutions that meet your business objectives.</div>
                </div>
</div>
              </a>
            </div>
            
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={ecom} class="mt-1" /></div>
                  <div class="feature-heading mt-2">E-Commerce Solutions</div>
                  <div class="feature-description pt-2">Unlock the potential of online sales with our robust e-commerce solutions. We develop feature-rich online stores that are intuitive to navigate, visually appealing, and equipped with powerful shopping cart functionalities to maximize conversions and enhance the customer experience.</div>
                  
                </div>
</div>
              </a>
            </div>

          </div>

          <div class="row mt-4">
          <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={cms} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Content Management Systems (CMS)</div>
                  <div class="feature-description pt-2">Take control of your website content with our user-friendly content management systems. We empower you to update and manage your website effortlessly, allowing you to add new pages, modify content, and integrate additional features without technical expertise.</div>
            
                </div>
</div>
              </a>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-left" data-aos-duration="1000">
                <div><img src={ui} class="mt-1" /></div>
                  <div class="feature-heading mt-2">UI/UX Design</div>
                  <div class="feature-description pt-2">Our talented designers create intuitive and engaging user interfaces (UI) that enhance the user experience (UX). By understanding your target audience and their needs, we craft interfaces that are visually appealing, easy to navigate, and optimized for conversions.</div>
               
                </div>
</div>
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <a href="#" class="decoration">
              <div class="mobile-box">
                <div class="mobile-feature-box" data-aos="flip-right" data-aos-duration="2000">
                <div><img src={maintain} class="mt-1" /></div>
                  <div class="feature-heading mt-2">Website Maintenance and Support</div>
                  <div class="feature-description pt-2"> We offer comprehensive website maintenance and support services to ensure your website remains secure, up-to-date, and operates at peak performance. From regular backups and security updates to bug fixes and feature enhancements, we've got you covered.</div>
                 
                </div>
</div>
              </a>
            </div>

          </div>
        </div>
      </div>
      <div class="container-fluid pb-5">
        <div class="container">
          <div class="started pt-5">HOW WE Works</div>
          <div class="start-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses,
            found during the year 2019 to<br />provide customized and efficient software . </div>
          <div class="video-wrapper mt-5">
            <video controls class="video" poster={overlap}>
              <source src={webvideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div class="container-fluid deep-blue pb-5">
        <div class="container">
          <div class="portfolio pt-5">WHY CHOOSE US</div>
          <div class="zasta-portfolio pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses
          </div>
          <div class="row mt-5">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Experienced Team</div>
                                <div class="catagory-description pt-2">Our team consists of skilled professionals with extensive experience in web design, development, and digital marketing. We bring expertise from various industries to deliver solutions that align with your business goals.</div>
                            </div>
</div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Client Approach</div>
                                <div class="catagory-description pt-2">We prioritize understanding your business objectives and target audience to develop tailored solutions that resonate with your brand and drive measurable results. We collaborate closely with you throughout the process to ensure your vision is brought to life.
                                </div>
                            </div>
</div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Quality Assurance</div>
                                <div class="catagory-description pt-2">We adhere to rigorous quality standards and conduct thorough testing to ensure that your website performs flawlessly across browsers, devices, and platforms. We are committed to delivering a seamless user experience and pixel-perfect design.
                                </div>
                            </div>
</div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="mobile-box">
                            <div class="catagory-box" data-aos="flip-left" data-aos-duration="1000">
                                <div class="catagory-name">Timely Delivery</div>
                                <div class="catagory-description pt-2">We understand the importance of meeting deadlines. Our streamlined processes and efficient project management enable us to deliver your website on time without compromising on quality.
                                </div>
                            </div>
</div>
                    </div>
                </div>

          {/* <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 pt-5">
              <div class="nav flex-column nav-pills me-3 mt-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <button class="nav-link active v-nav-link active w-100" id="v-pills-high-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-high" type="button" role="tab" aria-controls="v-pills-high"
                  aria-selected="true">
                  <div class="d-flex">
                    <div><img src={hd} /></div>
                    <div class="features-v pt-1">Experienced Team</div>
                  </div>
                  <div class="explain-features">Our team consists of skilled professionals with extensive experience in web design, development, and digital marketing. We bring expertise from various industries to deliver solutions that align with your business goals.</div>
                </button>
                <button class="nav-link v-nav-link w-100" id="v-pills-responsive-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-responsive" type="button" role="tab" aria-controls="v-pills-responsive"
                  aria-selected="false">
                  <div class="d-flex">
                    <div><img src={hd} /></div>
                    <div class="features-v pt-1">Client-Centric Approach</div>
                  </div>
                  <div class="explain-features">We prioritize understanding your business objectives and target audience to develop tailored solutions that resonate with your brand and drive measurable results. We collaborate closely with you throughout the process to ensure your vision is brought to life.</div>
                </button>
                <button class="nav-link v-nav-link w-100" id="v-pills-quality-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-quality" type="button" role="tab" aria-controls="v-pills-quality"
                  aria-selected="false">
                  <div class="d-flex">
                    <div><img src={hd} /></div>
                    <div class="features-v pt-1">Quality Assurance</div>
                  </div>
                  <div class="explain-features">We adhere to rigorous quality standards and conduct thorough testing to ensure that your website performs flawlessly across browsers, devices, and platforms. We are committed to delivering a seamless user experience and pixel-perfect design.</div>
                </button>
                <button class="nav-link v-nav-link w-100" id="v-pills-social-tab" data-bs-toggle="pill"
                  data-bs-target="#v-pills-social" type="button" role="tab" aria-controls="v-pills-social"
                  aria-selected="false">
                  <div class="d-flex">
                    <div><img src={hd} /></div>
                    <div class="features-v pt-1">Timely Delivery</div>
                  </div>
                  <div class="explain-features">We understand the importance of meeting deadlines. Our streamlined processes and efficient project management enable us to deliver your website on time without compromising on quality.</div>
                </button>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-high" role="tabpanel"
                  aria-labelledby="v-pills-high-tab">
                  <img src={quality} class="w-100" />
                </div>
                <div class="tab-pane fade mt-5" id="v-pills-responsive" role="tabpanel"
                  aria-labelledby="v-pills-responsive-tab">
                  <img src={quality} class="w-100" />
                </div>
                <div class="tab-pane fade mt-5" id="v-pills-quality" role="tabpanel" aria-labelledby="v-pills-quality-tab">
                  <img src={quality} class="w-100" />
                </div>
                <div class="tab-pane fade mt-5" id="v-pills-social" role="tabpanel" aria-labelledby="v-pills-social-tab">
                  <img src={quality} class="w-100" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div class="container-fluid yellow pt-5">
        <section id="testimonial-area">
          <div class="container">
            <div class="testi-wrap">
              <div class="client-single active position-1" data-position="position-1">
                <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  <img src={person1} alt="" />
                </div>
                <div class="client-comment" data-aos="zoom-in" data-aos-duration="1500">
                  <h3>Zasta Infotek is better company as recommended for web development and all things.</h3>
                </div>
                <div class="client-info" data-aos="zoom-in" data-aos-duration="1500">
                  <img src={smartual} />
                  <p class="client-name">David Willey - CEO OF SMARTUAL</p>
                </div>
              </div>

              <div class="client-single inactive position-2" data-position="position-2">
                <div class="client-img" data-aos="fade-up" data-aos-duration="2000">
                  <img src={person2} alt="" />
                </div>
                <div class="client-comment">
                  <h3>Zasta Infotek is better company as recommended for web development and all things.</h3>
                </div>
                <div class="client-info">
                  <img src={smartual} />
                  <p class="client-name">David Willey - CEO OF SMARTUAL</p>
                </div>
              </div>

              <div class="client-single inactive position-3" data-position="position-3">
                <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  <img src={person3} />
                </div>
                <div class="client-comment">
                  <h3>Zasta Infotek is better company as recommended for web development and all things.</h3>
                </div>
                <div class="client-info">
                  <img src={smartual} />
                  <p class="client-name">David Willey - CEO OF SMARTUAL</p>
                </div>
              </div>

              <div class="client-single inactive position-5" data-position="position-5">
                <div class="client-img" data-aos="fade-up" data-aos-duration="2000">
                  <img src={person4} alt="" />
                </div>
                <div class="client-comment">
                  <h3>Zasta Infotek is better company as recommended for web development and all things.</h3>
                </div>
                <div class="client-info">
                  <img src={smartual} />
                  <p class="client-name">David Willey - CEO OF SMARTUAL</p>
                </div>
              </div>

              <div class="client-single inactive position-6" data-position="position-6">
                <div class="client-img" data-aos="fade-down" data-aos-duration="2000">
                  <img src={person5} alt="" />
                </div>
                <div class="client-comment">
                  <h3>Zasta Infotek is better company as recommended for web development and all things.</h3>
                </div>
                <div class="client-info">
                  <img src={smartual} />
                  <p class="client-name">David Willey - CEO OF SMARTUAL</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div> */}

    </div>
  );
}

export default Web;
