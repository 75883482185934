import React, { useState } from "react";
import { db } from '../firebase';
import emailjs from "emailjs-com";
import phone from '../assets/images/phone.svg';
import locate from '../assets/images/locate.svg';
import mail from '../assets/images/mail.svg';
import facebook from '../assets/images/facebook.svg'
import twitter from '../assets/images/twitter.svg';
import linkedin from '../assets/images/linked-in.svg';

function SendQuery() {
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_3bq4agp', 'template_xpc1pcr', e.target, "tPVKUKbHFllq5r78n").then(res => {
            console.log(res);
        }).catch(err => console.log(err));
    }
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");

    const [loader, setLoader] = useState(false);

    const handleSubmit = (e) => {
        sendEmail(e)
        e.preventDefault();
        setLoader(true);

        db.collection("contacts")
            .add({
                name: name,
                email: email,
                number: number,
                message: message,
            })
            .then(() => {
                setLoader(false);
                alert("Your message has been submitted👍");
            })
            .catch((error) => {
                alert(error.message);
                setLoader(false);
            });

        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
    };
    return (
        <div class="container pb-5">
            <div class="project pt-5">TELL US Your Requirement</div>
            <div class="zasta-project pt-3">Please Submit Your query and Our Team will contact you soon ! </div>
            <div class="project-box mt-5">
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="information-box" data-aos="flip-left" data-aos-duration="1500">
                            <div class="zasta-data">Zasta Infotek Pvt. Ltd.</div>
                            <div class="zasta-description-data pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses, found during the year 2019 to provide customized and efficient software .</div>
                            <div class="data-box mt-4">
                                <div class="d-flex">
                                    <div><img src={phone} /></div>
                                    <div class="data-box-data pt-1">+91 88386 25041 /+91 8056163769</div>
                                </div>
                            </div>
                            <div class="data-box mt-3">
                                <div class="d-flex">
                                    <div><img src={mail} /></div>
                                    <div class="data-box-data pt-1">info@zastainfotek.com </div>
                                </div>
                            </div>
                            <div class="data-box mt-3">
                                <div class="d-flex">
                                    <div><img src={locate} /></div>
                                    <div class="data-box-data pt-1">A13-304, SSM Nagar, Alapakkam, New<br />Perugalathur, Chennai – 600063.</div>
                                </div>
                            </div>
                            <div class="d-flex mt-5">
                                <div><a href="https://www.facebook.com/zastainfotek"><img src={facebook} /></a></div>
                                <div><a href="#"><img src={twitter} class="icon-space" /></a></div>
                                <div><a href="#"><img src={linkedin} class="icon-space" /></a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <form onSubmit={handleSubmit}>
                            <div class="req-field mt-4"><span class="important">*</span> Your Name</div>
                            <input type="text" class="mt-1 gray" value={name} name="name"
                                onChange={(e) => setName(e.target.value)}
                                required />
                            <div class="row mt-2">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div class="req-field mt-4"><span class="important">*</span> Your E-mail</div>
                                    <input type="text" class="mt-1 gray" value={email} name="user_email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        required />
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <div class="req-field mt-4"><span class="important">*</span> Your Phone Number</div>
                                    <input type="text" class="mt-1 gray" value={number} name="number"
                                        onChange={(e) => setNumber(e.target.value)}
                                        required />
                                </div>
                            </div>
                            <div class="req-field pt-4">What do you need</div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="flex-checkbox mt-3">
                                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                                            id="radioNoLabel1" value="" aria-label="" />
                                        <div class="needs mt-1">&nbsp; Web Development</div>
                                    </div>
                                    <div class="flex-checkbox mt-3">
                                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                                            id="radioNoLabel1" value="" aria-label="" />
                                        <div class="needs mt-1">&nbsp; Graphic Design</div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-12">
                                    <div class="flex-checkbox mt-3">
                                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                                            id="radioNoLabel1" value="" aria-label="" />
                                        <div class="needs mt-1">&nbsp; Mobile App Development</div>
                                    </div>
                                    <div class="flex-checkbox mt-3">
                                        &nbsp; <input class="form-check-input" type="radio" name="radioNoLabel"
                                            id="radioNoLabel1" value="" aria-label="" />
                                        <div class="needs mt-1">&nbsp; Others</div>
                                    </div>
                                </div>
                            </div>
                            <div class="req-field pt-4">Message</div>
                            <input type="text" placeholder="(230 words)" name="message" class="mt-3 gray" value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required />
                            <button class="submit-button-project float-end mt-5" id="navigation" style={{ background: loader ? "#ccc" : " rgb(2, 2, 110)" }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SendQuery;