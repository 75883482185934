import firebase from "firebase";
import 'firebase/analytics';

var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyBniOKvAgGBzvK82ys72AmtqRB2VsOsdXU",
  authDomain: "contact-form-b16db.firebaseapp.com",
  projectId: "contact-form-b16db",
  storageBucket: "contact-form-b16db.appspot.com",
  messagingSenderId: "28356313268",
  appId: "1:28356313268:web:baf94eb9417e2de251d7e1"
});

var db = firebaseApp.firestore();
const analytics = firebase.analytics();


export { db, analytics };


