import React, { useState } from "react";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import "./Home.css";
import "aos/dist/aos.css";
import AOS from "aos";

function HomeBanner() {
  const [isVideoPlaying, setVideoPlaying] = useState(false);

  const opts = {
    height: "300", // Adjust the height as needed
    width: "100%",
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const onThumbnailClick = () => {
    setVideoPlaying(true);
  };

  AOS.init();

  return (
    <div className="banner-container">
      <div className="text-content" data-aos="fade-down" data-aos-duration="1500">
        <h1 className="started">
          Welcome to Zasta Infotek Private Limited
        </h1>
        <h2 className="start-description" data-aos="fade-right" data-aos-duration="1500">
          Elevating Businesses through Innovative Technology Solutions
        </h2>
      </div>

      <div className="video-background">
        {!isVideoPlaying && (
          <div onClick={onThumbnailClick} className="video-thumbnail">
            <img
              src={`https://img.youtube.com/vi/1VpovS4u-vo/maxresdefault.jpg`}
              alt="Video Thumbnail"
              width="100%"
            />
          </div>
        )}
        {isVideoPlaying && (
          <YouTube
            videoId="1VpovS4u-vo"
            opts={opts}
            onReady={(event) => {
              event.target.playVideo();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default HomeBanner;
