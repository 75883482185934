import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import EcommerceComponent from '../components/Ecommerce/Ecommerce';
import Footer from '../components/Footer';
// import Chatbot from '../components/Chatbot'; // Uncomment if you want to include the Chatbot

function Ecommerce() {
  return (
    <>
      {/* Uncomment the line below if you want to include the Chatbot */}
      {/* <Chatbot /> */}
      
      <Header />
      <Helmet>
        <title>Zasta Infotek: Transforming Ecommerce with Innovative Solutions</title>
        <meta
          name="description"
          content="Elevate your online business with Zasta Infotek's innovative ecommerce solutions. We specialize in creating user-friendly and scalable ecommerce platforms, driving your digital storefront to success."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Ecommerce Solutions, Online Store Development, Ecommerce Platforms, Digital Storefront, Ecommerce Development, Online Retail"
        />
      </Helmet>
      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Transforming Ecommerce with Innovative Solutions</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Elevate Your Online Business with Zasta Infotek's Expertise
          </div>
        </h2>
      
   
      
      <EcommerceComponent />
      <Footer />
    </>
  );
}

export default Ecommerce;
