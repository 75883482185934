import React from "react";
import Header from '../Header';
import Chatbot from "../Chatbot";
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import bill from '../../assets/images/product-bill.svg';
import ecom from '../../assets/images/product-ecom.svg';

const Products = () =>{
  return (
    <div className="App">
      <Chatbot />
      <Header />
      <div class="container-fluid navy-blue pb-5">
        <div class="container">
          <div class="row pt-5">
          <div class="portfolio">Our Products</div>
          <div class="zasta-portfolio pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses
          </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="product-heading mt-5" data-aos="fade-right" data-aos-duration="1000">Zasta Billing</div>
              <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">No tricky or manual billing anymore Zasta is here to carry out
                    raise the invoice in a better easy way with automated software. Zasta billing service trump card is
                    to attract the business customer and promise high level satisfaction/ fulfillment. We provide GST
                    billing services to small scale and medium scale and all level businesses.
                </div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-4">
                    <a href="https://zastabilling.com/" target="_blank"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Know More</button></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div data-aos="fade-down" data-aos-duration="1500">
                <img src={bill} class="w-100 mt-5 main-img" alt="bill" />
              </div>
            </div>
          </div>

          <div class="row pt-5 flex-column-reverse flex-sm-row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div data-aos="fade-down" data-aos-duration="1500">
                <img src={ecom} class="w-100 mt-5 main-img" alt="ecom" />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="product-heading mt-5" data-aos="fade-right" data-aos-duration="1000">Zasta E-commerce</div>
              <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">Providing E-commerce solutions for seamless shopping experience to your customers. We build E-commerce Mobile app and web with your products which is effective way of giving market exposure to your business.
                </div>
              <div class="row">
                <div class="col-lg-5 col-md-5 col-sm-6 col-6">
                  <div class="mt-4">
                    <a href="https://www.zastacommerce.com/" target="_blank"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Know More</button></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
        </div>
  );
}

export default Products;
