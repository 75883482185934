
import React, { useState } from 'react';
import './App.css';
import Home from './pages/Home';
import Staffing from './pages/Staffing';
import WebDevelopment from './pages/Web';
import MobileDevelopment from './pages/Mobile';
import DigitalMarketing from './pages/Digital';
import AboutUs from './pages/About';
import ContactUs from './pages/Contact';
import Quality from './pages/Quality';
import Ecommerce from './pages/Ecommerce';
import Privacy from './Privacy';
import Terms from './Terms';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Products from './components/OurProducts/Products';
import Chatbot from './components/Chatbot';



function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/staffing' exact element={<Staffing />} />
        <Route path='/web-development' exact element={<WebDevelopment />} />
        <Route path='/mobile-development' exact element={<MobileDevelopment />} />
        <Route path='/digital-marketing' exact element={<DigitalMarketing />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/quality' element={<Quality />} />
        <Route path='/ecommerce' element={<Ecommerce />} />
        <Route path='/Products' element={<Products />} />
        {/* <Route path='/about' element={<About />} /> */}
        {/* <Route path='/contact' element={<Contact />} /> */}
        {/* <Route path='/ecommerce' element={<Ecommerce />} /> */}
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/Chatbot' element={<Chatbot />} />
        {/* <Route path='/digital' element={<Digital />} /> */}
        {/* <Route path='/mobile' element={<Mobile />} /> */}
        {/* <Route path='/support' element={<Support />} /> */}
        {/* <Route path='/form' element={<Form />} /> */}
      </Routes>
    </Router>
  );
}


export default App;
