import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import DigitalMarketing from '../components/DigitalMarketing/Digital';
import Footer from '../components/Footer';
import Chatbot from '../components/Chatbot';

function Digital() {
  return (
    <>
      <Helmet>
        <title>Zasta Infotek: Results-Driven Digital Marketing Services</title>
        <meta
          name="description"
          content="Unlock the full potential of your online presence with Zasta Infotek's results-driven digital marketing services. Our experts specialize in SEO, social media, content marketing, and more to elevate your brand in the digital landscape."
        />
        <meta
          name="keywords"
          content="Zasta Infotek, Digital Marketing, SEO, Social Media Marketing, Content Marketing, Online Advertising, Digital Marketing Solutions"
        />
      </Helmet>

      <Chatbot />
      <Header />
      <h1>
          <div className="started pt-5" data-aos="fade-down" data-aos-duration="1500">Results-Driven Digital Marketing Services</div>
        </h1>
        <h2>
          <div className="start-description pt-3" data-aos="zoom-in">
          Unlock Your Online Potential with Zasta Infotek's Expertise
          </div>
        </h2>

      
      <DigitalMarketing />
      <Footer />
    </>
  );
}

export default Digital;
