import { BrowserRouter as Router, Route, Link, NavLink, Switch } from 'react-router-dom';
import home from '../../assets/images/zasta-home.png';
import infotek from '../../assets/video/zasta-infotek.mp4';
import Tick from '../../assets/images/tick.svg';
import vision from '../../assets/images/vision-size.webp';
import mission from '../../assets/images/mission.webp';
import value from '../../assets/images/values-size.jpg';
import React from 'react';
import './Home.css';


function VisionStatement() {
    return (
        <div class="container">
            <div class="started pt-5">VISION, MISSION & VALUES </div>
            {/* <br />INTRODUCING WAY</div> */}
            <div class="start-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses, found during
                the year 2019<br />to provide customized and efficient software . </div>
            <div class="row mt-5">
                <div class="col-lg-3"></div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <ul class="nav nav-pills center mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                                type="button" role="tab" aria-controls="pills-home" aria-selected="true">VISION</button>
                        </li>
                        <li class="nav-item mission" role="presentation">
                            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">MISSION</button>
                        </li>
                        <li class="nav-item value" role="presentation">
                            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact"
                                type="button" role="tab" aria-controls="pills-contact" aria-selected="false">VALUES</button>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3"></div>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="row mt-5">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div>
                                 <img src={vision} class="vision-img" alt='vision'/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="tab-heading pt-2">VISION</div>
                            {/* <div class="tab-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses,
                                found during the year 2019 to provide customize.</div> */}
                            <div class="d-flex pt-3">
                                <img src={Tick} class="pt-3" alt='tick'/>
                                <div class="tab-points">excellence</div>
                            </div>
                            <div class="d-flex">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Satisfaction</div>
                            </div>
                            <div class="d-flex">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Creativity</div>
                            </div>
                            <div class="d-flex">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Quality</div>
                            </div>
                            {/* <button class="learn-more-tab-button mt-4">Learn more</button> */}
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div class="row mt-5">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                        <div>
                                 <img src={mission} class="vision-img" alt='mission'/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="tab-heading pt-2">MISSION</div>
                            {/* <div class="tab-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses,
                                found during the year 2019 to provide customize.</div> */}
                            <div class="d-flex pt-3">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Brilliance and Perfection</div>
                            </div>
                            <div class="d-flex">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Customize and deliver with 100% accuracy</div>
                            </div>
                            {/* <button class="learn-more-tab-button mt-4">Learn more</button> */}
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <div class="row mt-5">
                        <div class="col-lg-6 col-md-12 col-sm-12">
                        <div>
                                 <img src={value} class="vision-img" alt='value'/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12">
                            <div class="tab-heading pt-2">VALUES</div>
                            {/* <div class="tab-description pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses,
                                found during the year 2019 to provide customize.</div> */}
                            <div class="d-flex pt-3">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Customer Satisfaction</div>
                            </div>
                            <div class="d-flex">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Hard work with Smart work</div>
                            </div>
                            <div class="d-flex">
                                <img src={Tick} class="pt-3" alt='tick' />
                                <div class="tab-points">Build confidence in oneself to success</div>
                            </div>
                            {/* <button class="learn-more-tab-button mt-4">Learn more</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisionStatement;