import React, { useState } from "react";
import './Ecommerce.css';
import { Link}from 'react-router-dom';
import main from '../../assets/images/e-commerce-main.svg';
import star from '../../assets/images/star.svg';
import tp from'../../assets/images/tick-plan.svg';
import st from '../../assets/images/suggest-tick.svg';
import ecommerce from '../../assets/images/e-commerce-img.svg';
const Ecommerce = () =>{
  

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  
  return (
    <div className="App">
     <div class="container-fluid navy-blue">
   <div class="container">
     <div class="row pt-5">
       <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="web-design mt-5" data-aos="fade-right" data-aos-duration="1000">E-Commerce Platform</div>
        <div class="company-txt mt-3" data-aos="fade-right" data-aos-duration="1000">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses, found during the year 2019 to.</div>
        <div class="row">
          <div class="col-lg-5 col-md-5 col-sm-6 col-6">
            <div class="mt-5">
            <Link to="/contact-us"><button class="talk-button" data-aos="fade-up" data-aos-duration="1500">Talk to Us</button></Link>
          </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-6 col-6">
            <div class="mt-5">
          <button class="learn-more-button" data-aos="fade-up" data-aos-duration="1500">Learn More</button>
          </div>
          </div>
        </div>
       </div>
       <div class="col-lg-6 col-md-12 col-sm-12">
        <div data-aos="fade-down" data-aos-duration="1500">
        <img src={main} class="w-100 mt-5 e-commerce-main" alt="main"/>
        </div>
      </div>
     </div>
   </div>
  </div>
  <div class="container">
  <div class="started pt-5">What We do?</div>
      <div class="start-description pt-3">The globe revolve around internet</div>
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="heading mt-5">E-commerce</div>
            <div class="sub-heading mt-4">Providing E-commerce solutions for seamless shopping
                    experience to your customers. We build Ecommerce Mobile app and web with your products which is
                    effective way of giving market exposure to your business.
            </div>
            <div class="sub-heading mt-4">Having your own ecommerce website helps you better understand your customers. This includes demographics such as their location, as well as how they found your website and heard about you. You can analyze their behavior on your website, like what they looked at and the path they took to buy from you.</div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <img src={ecommerce} class="center w-100 mt-5" alt="e-commerce"></img>
        </div>
    </div>
  </div>
  <div class="container-fluid pricing-section pb-5 mt-5">
    <div class="container">
      <div class="pricing-heading pt-5">OUR PRICING PLANS</div>
      <div class="pricing-company pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses, found during the<br/> year 2019 to provide customized and efficient software .</div>
      <div class="row mt-5">
        <div class="col-lg-4 col-md-12 col-sm-12">
       <div class="pricing-box mt-5" data-aos="fade-down" data-aos-duration="1500">
       <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
          <div class="price-plan">Economy</div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
          <div class="flex">
          <div class="price">138$</div>
          <div class="price-duration">&nbsp;/monthly</div>
          </div>
        </div>
       </div>
       <div class="flexible">
       <img src={star} class="mt-2" alt="star"></img>
       <div class="rating mt-3">&nbsp;5.0</div>
       </div>
       <div class="plan-description mt-3">All the basic for boost your plan</div>
       <div class="flexible mt-3">
        <div><img src={tp} alt="tp"></img></div>
        <div class="plans">&nbsp;Domain ( Free for 1year ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp} alt="tp"></img></div>
        <div class="plans">&nbsp;Hosting ( Free for 1year ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp} alt="tp"></img></div>
        <div class="plans">&nbsp;SSL ( Free for 1year ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp} alt="tp"></img></div>
        <div class="plans">&nbsp;Products ( Up to 50 ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp} alt="tp"></img></div>
        <div class="plans">&nbsp;Support ( 1 Month)</div>
       </div>
       <button class="plan-button mt-4">GET ECONOMY PLAN</button>
       </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12">
        <div class="suggest-box" data-aos="fade-up" data-aos-duration="1500">
          <div class="suggest pt-2">
          RECOMMENDED
          </div>
       <div class="row mt-5">
        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
          <div class="price-plan-suggest">Standard</div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
          <div class="flex">
          <div class="price-suggest">138$</div>
          <div class="price-duration-suggest">&nbsp;/monthly</div>
          </div>
        </div>
       </div>
       <div class="flexible">
       <img src={star} class="mt-2" alt="star"></img>
       <div class="rating-suggest mt-3">&nbsp;5.0</div>
       </div>
       <div class="plan-description-suggest mt-4">All the basic for boost your plan</div>
       <div class="flexible mt-3">
        <div><img src={st} alt="tp"></img></div>
        <div class="plans-suggest">&nbsp;Domain ( Free for 2 years ) </div>
       </div>
       <div class="flexible mt-4">
        <div><img src={st} alt="tp"></img></div>
        <div class="plans-suggest">&nbsp;Hosting ( Free for 2 years ) </div>
       </div>
       <div class="flexible mt-4">
        <div><img src={st} alt="tp"></img></div>
        <div class="plans-suggest">&nbsp;SSL ( Free for 2 years ) </div>
       </div>
       <div class="flexible mt-4">
        <div><img src={st} alt="tp"></img></div>
        <div class="plans-suggest">&nbsp;Products ( Up to 200 ) </div>
       </div>
       <div class="flexible mt-4">
        <div><img src={st} alt="tp"></img></div>
        <div class="plans-suggest">&nbsp;Support ( 3 Months )</div>
       </div>
       <button class="plan-button mt-5">GET STANDARD PLAN</button>
       </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="pricing-box mt-5" data-aos="fade-down" data-aos-duration="1500">
       <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
          <div class="price-plan">Pro</div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-6">
          <div class="flex">
          <div class="price">138$</div>
          <div class="price-duration">&nbsp;/monthly</div>
          </div>
        </div>
       </div>
       <div class="flexible">
       <img src={star} class="mt-2"></img>
       <div class="rating mt-3">&nbsp;5.0</div>
       </div>
       <div class="plan-description mt-3">All the basic for boost your plan</div>
       <div class="flexible mt-3">
        <div><img src={tp}></img></div>
        <div class="plans">&nbsp;Domain ( Free for 2 years ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp}></img></div>
        <div class="plans">&nbsp;Hosting ( Free for 2 years ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp}></img></div>
        <div class="plans">&nbsp;SSL ( Free for 2 years ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp}></img></div>
        <div class="plans">&nbsp;Products ( Up to 500 ) </div>
       </div>
       <div class="flexible mt-3">
        <div><img src={tp}></img></div>
        <div class="plans">&nbsp;Support ( 6 Months )</div>
       </div>
       <button class="plan-button mt-4">GET PRO PLAN</button>
       </div>
          </div>
      </div>
    </div>
  </div>
  <div class="container-fluid navy-blue pb-5">
    <div class="container">
      <div class="question pt-5">FREQUENTLY ASKED<br/>QUESTIONS</div>
      <div class="zasta-about-us pt-3">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses</div>
      <div class="row">
        <div class="col-lg-1 col-md-1 col-sm-12"></div>
        <div class="col-lg-10 col-md-10 col-sm-12">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item mt-5" data-aos="flip-down" data-aos-duration="1500">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  How do i pay for the premium plan to get more features?
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">There is NO such fees on any of our plans..</div>
              </div>
            </div>
            <div class="accordion-item" data-aos="flip-down" data-aos-duration="1700">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  Can I change my plan later on?
                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Certainly! You can adjust your plan anytime, Please contact us
                  for the same.
                </div>
              </div>
            </div>
            <div class="accordion-item" data-aos="flip-down" data-aos-duration="2000">
              <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  Is Offline Payment like Cash/Cheques/Direct transfer from Bank supported?
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">Yes, absolutely. You can accept cash, mailed cheques, money
                  orders, western
                  union, money gram, direct bank transfer. Give us the details of the payment
                  method to be displayed</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-12"></div>
      </div>
    </div>
  </div>

</div>
  );
}

export default Ecommerce;
