
import React from 'react';
import './App.css';
import nets from './assets/images/nets.svg';
import Header from './components/Footer';
import Footer from './components/Header';
function Terms() {
    return (
        <div className="App">
            <Header />
            <div class="container-fluid navy-blue">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 col-md-10 col-sm-12">
                            <div class="condition">Terms of Conditions</div>
                            <div class="zasta-privacy pt-4">Zasta Infotek Pvt Ltd is a Technology service provider for any businesses,</div>
                            <ul class="nav nav-tabs mt-5" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="summary-tab" data-bs-toggle="tab" data-bs-target="#summary" type="button" role="tab" aria-controls="summary" aria-selected="true">SUMMARY</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="intro-tab" data-bs-toggle="tab" data-bs-target="#intro" type="button" role="tab" aria-controls="intro" aria-selected="false">INTRODUCTION</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#legal" type="button" role="tab" aria-controls="legal" aria-selected="false">LEGAL DISCLAIMER</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="content-tab" data-bs-toggle="tab" data-bs-target="#content" type="button" role="tab" aria-controls="content" aria-selected="false">CONTENT</button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                            <img src={nets} class="hide pt-5" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="summary" role="tabpanel" aria-labelledby="summary-tab">
                        <div>
                            <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                            <div class="policy-heading mt-3">General information</div>
                            <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                            <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                            <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                            <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                            <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                            <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                            <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                            <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                            <div class="policy-para mt-3">Contact forms other forms of contact</div>
                            <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="intro-tab">
                        <div>
                            <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                            <div class="policy-heading mt-3">General information</div>
                            <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                            <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                            <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                            <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                            <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                            <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                            <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                            <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                            <div class="policy-para mt-3">Contact forms other forms of contact</div>
                            <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="legal" role="tabpanel" aria-labelledby="legal-tab">
                        <div>
                            <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                            <div class="policy-heading mt-3">General information</div>
                            <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                            <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                            <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                            <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                            <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                            <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                            <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                            <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                            <div class="policy-para mt-3">Contact forms other forms of contact</div>
                            <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                        </div>
                    </div>
                    <div class="tab-pane fade show active" id="content" role="tabpanel" aria-labelledby="content-tab">
                        <div>
                            <div class="policy-para mt-5">We value your trust in providing us your data, thus we strive to use commercially acceptable means of protecting it. Below you will find the necessary information about the data we collect and process when you use our website (www.zastaInfotek.com – hereinafter as “Website”).</div>
                            <div class="policy-heading mt-3">General information</div>
                            <div class="policy-para mt-3">The controller of your personal data collected by the Website is Miquido Spółka z ograniczoną odpowiedzialnością sp. k. with its seat in Krakow, Zabłocie 43a, 30-701 Kraków, Poland (“Miquido”, “We”, “Our” or “Us”).</div>
                            <div class="policy-para mt-3">In all cases regarding your personal data you can contact Us:</div>
                            <div class="policy-para mt-3">by mail – ul. Zabłocie 43a, 30-701 Kraków, Poland;</div>
                            <div class="policy-para mt-3">by phone – +48 536 083 559;</div>
                            <div class="policy-para mt-3">by e-mail – gpdr@miquido.com.</div>
                            <div class="policy-para mt-3">Your personal data will be processed by the Administrator in accordance with the applicable law, in particular in accordance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation)- hereinafter referred to as “GDPR”.</div>
                            <div class="policy-para mt-3">We guarantee the confidentiality of all personal data provided to us. We use appropriate technical and organizational safeguards, thanks to which we can ensure that the data is adequately protected against unauthorized access.</div>
                            <div class="policy-para mt-3">The purposes of the processing and legal basis</div>
                            <div class="policy-para mt-3">Contact forms other forms of contact</div>
                            <div class="policy-para mt-3">Personal data sent to the Administrator via Contact Forms or other available forms of contact will be processed in order to answer your questions, contact you and conduct business communication. The legal basis for processing personal data is the Administrator’s legitimate interest (Article 6 (1) (f) of the GDPR). If you agree to receive commercial offers, your personal data will be processed on the basis of art. 6 sec. 1 lit. b GDPR, i.e. activities aimed at concluding a contract. Your personal data will be processed for the period necessary to handle the correspondence. However, if as a result of the submitted inquiry any cooperation or business relationship is established, the period of storage of the correspondence and the personal data contained therein will be extended for the duration of this relationship, conclusion of the contract, the limitation period for claims resulting from the concluded contract and for tax settlements. Providing personal data in the scope of name, surname and e-mail address is voluntary, but necessary in order to respond to your messages.</div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Terms;
